import React from 'react'
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableContainer,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'

interface DailyValidation {
  day: string
  date: any
  dayRevenue: number
  value: number
}

interface ValidationData {
  id: string
  location: {
    locationName: string
    locationRc: string
    standNumber: string
  }
  stand: string
  name: string
  validationRate: string
  dailyValidations: DailyValidation[]
}

type Order = 'asc' | 'desc'

interface DailyValidationTableProps {
  validationData: ValidationData[]
  stands: string // Conditionally show/hide stand column
  triggerExport: boolean // Detect export trigger from parent
  onExportComplete: () => void // Callback to reset trigger in parent
}

const DailyValidationTable: React.FC<DailyValidationTableProps> = ({
  validationData,
  stands,
  triggerExport,
  onExportComplete,
}) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('location.locationName')

  const columnMapping: Record<string, string> = {
    'LOCATION RC': 'location.locationRc',
    LOCATION: 'location.locationName',
    'STAND #': 'stand',
    NAME: 'name',
    DAY: 'day',
    DATE: 'date',
    CARS: 'value',
    'DAY REVENUE': 'dayRevenue',
  }

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const formatDate = (date: any) => {
    if (!date) return 'Invalid Date'

    // Convert to UTC and format as MM/DD/YYYY
    return moment.utc(date.seconds * 1000).format('MM/DD/yyyy')
  }

  const aggregatedData = React.useMemo(() => {
    if (stands === 'Stand 1') {
      const groupedData: Record<string, any> = {}

      validationData.forEach((data) => {
        data?.dailyValidations?.forEach((daily) => {
          const key = `${formatDate(daily.date)}-${data.location.locationRc}-${
            data.location.locationName
          }-${data.name}-${data.validationRate}`

          if (!groupedData[key]) {
            groupedData[key] = {
              ...data,
              ...daily,
              value: 0,
              dayRevenue: 0,
            }
          }

          groupedData[key].value += daily.value || 0
          groupedData[key].dayRevenue += daily.dayRevenue || 0
        })
      })

      return Object.values(groupedData)
    }

    return validationData.flatMap((data) =>
      data?.dailyValidations?.map((daily) => ({
        ...data,
        ...daily,
      }))
    )
  }, [validationData, stands])

  const sortedData = React.useMemo(() => {
    return [...aggregatedData].sort((a, b) => {
      const getValue = (obj: any, path: string): any =>
        path
          .split('.')
          .reduce(
            (o, key) => (o && typeof o === 'object' ? o[key] : undefined),
            obj
          )

      const aValue = getValue(a, orderBy)
      const bValue = getValue(b, orderBy)

      if (orderBy === 'location.locationRc') {
        const aRc = parseInt(aValue, 10) || 0
        const bRc = parseInt(bValue, 10) || 0

        return order === 'asc' ? aRc - bRc : bRc - aRc
      }

      if (orderBy === 'name' || orderBy === 'day') {
        const aString = typeof aValue === 'string' ? aValue.toLowerCase() : ''
        const bString = typeof bValue === 'string' ? bValue.toLowerCase() : ''

        return order === 'asc'
          ? aString.localeCompare(bString)
          : bString.localeCompare(aString)
      }

      if (orderBy === 'date') {
        const aDate =
          aValue instanceof Timestamp ? aValue.toDate() : new Date(aValue || 0)
        const bDate =
          bValue instanceof Timestamp ? bValue.toDate() : new Date(bValue || 0)

        return order === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime()
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? aValue - bValue : bValue - aValue
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue)
      }

      return 0
    })
  }, [aggregatedData, order, orderBy])

  const handleExport = () => {
    const headers = [
      'LOCATION RC',
      'LOCATION',
      stands !== 'Stand 1' ? 'STAND #' : '',
      'NAME',
      'DAY',
      'DATE',
      'CARS',
      'DAY REVENUE',
    ].filter(Boolean)

    let csvContent = headers.join(',') + '\n'

    sortedData.forEach((data) => {
      const row = [
        data.location.locationRc,
        data.location.locationName,
        stands !== 'Stand 1' ? data.stand : '',
        `${data.name} ${data.validationRate}`,
        data.day,
        formatDate(data.date),
        data.value,
        data.dayRevenue.toFixed(2),
      ]
        .filter(Boolean)
        .join(',')

      csvContent += row + '\n'
    })

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.setAttribute('download', 'daily-validation-report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    onExportComplete() // Notify parent to reset the export trigger
  }

  // Detect export trigger
  React.useEffect(() => {
    if (triggerExport) {
      handleExport()
    }
  }, [triggerExport])

  return (
    <Box
      sx={{
        width: { xs: '100%', md: 'calc(100vw - 30vw)' },
        overflowX: 'scroll',
        padding: { xs: '1px', md: '40px' },
        zIndex: 10,
      }}
    >
      <Typography variant='h6' mb={1}>
        Daily Validation Report
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ bgcolor: '#EAEAEA' }}>
              {[
                'LOCATION RC',
                'LOCATION',
                stands !== 'Stand 1' ? 'STAND #' : null,
                'NAME',
                'DAY',
                'DATE',
                'CARS',
                'DAY REVENUE',
              ]
                .filter(Boolean)
                ?.map((headCell, index) => (
                  <TableCell key={index}>
                    <TableSortLabel
                      active={orderBy === columnMapping[headCell as string]}
                      direction={
                        orderBy === columnMapping[headCell as string]
                          ? order
                          : 'asc'
                      }
                      onClick={() =>
                        handleRequestSort(columnMapping[headCell as string])
                      }
                    >
                      {headCell}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data?.location?.locationRc}</TableCell>
                <TableCell>{data?.location?.locationName}</TableCell>
                {stands !== 'Stand 1' && <TableCell>{data?.stand}</TableCell>}
                <TableCell>{`${data?.name} ${data?.validationRate}`}</TableCell>
                <TableCell>{data?.day}</TableCell>
                <TableCell>{formatDate(data?.date)}</TableCell>
                <TableCell>{data?.value}</TableCell>
                <TableCell>{data?.dayRevenue?.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default DailyValidationTable
