import React from 'react'
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableContainer,
  Typography,
} from '@mui/material'

interface ValidationData {
  id: string
  location: {
    locationName: string
    locationRc: string
    standNumber: string
  }
  stand: string
  name: string
  validationRate: string
  total: number
  totalRevenue: number
}

type Order = 'asc' | 'desc'

interface ValidationSummaryReportTableProps {
  validationData: ValidationData[]
  stands: string // Conditionally show/hide stand column
  triggerExport: boolean // Detect export trigger from parent
  onExportComplete: () => void // Callback to reset trigger in parent
}

const ValidationSummaryReportTable: React.FC<
  ValidationSummaryReportTableProps
> = ({ validationData, stands, triggerExport, onExportComplete }) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('id')

  const columnMapping: Record<string, keyof ValidationData | string> = {
    'LOCATION RC': 'location.locationRc',
    LOCATION: 'location.locationName',
    'STAND #': 'stand',
    NAME: 'name',
    TOTAL: 'total',
    'TOTAL REVENUE': 'totalRevenue',
  }

  // Aggregate data if stands is set to hide
  const aggregatedData =
    stands === 'Stand 1'
      ? Object.values(
          validationData.reduce((acc, data) => {
            const locationKey = `${data.location.locationRc}-${data.location.locationName}-${data.name}-${data.validationRate}`

            if (!acc[locationKey]) {
              acc[locationKey] = { ...data, total: 0, totalRevenue: 0 }
            }

            acc[locationKey].total += data.total
            acc[locationKey].totalRevenue += data.totalRevenue
            return acc
          }, {} as { [key: string]: ValidationData })
        )
      : validationData

  const handleRequestSort = (property: string) => {
    const mappedProperty = columnMapping[property] || property
    const isAsc = orderBy === mappedProperty && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(mappedProperty as keyof ValidationData)
  }

  const getValue = (obj: any, path: string): any =>
    path
      .split('.')
      .reduce(
        (o, key) => (o && typeof o === 'object' ? o[key] : undefined),
        obj
      )

  const sortedData = [...aggregatedData].sort((a, b) => {
    const aValue =
      typeof orderBy === 'string' && orderBy.includes('.')
        ? getValue(a, orderBy)
        : a[orderBy as keyof ValidationData]

    const bValue =
      typeof orderBy === 'string' && orderBy.includes('.')
        ? getValue(b, orderBy)
        : b[orderBy as keyof ValidationData]

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue
    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue)
    }

    return 0
  })

  // Export Logic
  const handleExport = () => {
    const headers = [
      'LOCATION RC',
      'LOCATION',
      stands !== 'Stand 1' ? 'STAND #' : '',
      'NAME',
      'TOTAL',
      'TOTAL REVENUE',
    ].filter(Boolean)

    let csvContent = headers.join(',') + '\n'

    sortedData.forEach((data) => {
      const row = [
        data.location.locationRc,
        data.location.locationName,
        stands !== 'Stand 1' ? data.stand : '',
        `${data.name} ${data.validationRate}`,
        data.total,
        data.totalRevenue.toFixed(2),
      ]
        .filter(Boolean)
        .join(',')

      csvContent += row + '\n'
    })

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.setAttribute('download', 'validation-summary-report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    onExportComplete() // Notify parent to reset trigger
  }

  // Detect export trigger
  React.useEffect(() => {
    if (triggerExport) {
      handleExport()
    }
  }, [triggerExport])

  return (
    <Box
      sx={{
        width: { xs: '100%', md: 'calc(100vw - 30vw)' },
        overflowX: 'scroll',
        padding: { xs: '1px', md: '40px' },
        zIndex: 10,
      }}
    >
      <Typography variant='h6' mb={1}>
        Validation Summary Report
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ bgcolor: '#EAEAEA' }}>
              {[...Object.keys(columnMapping)]
                .filter((key) => stands !== 'Stand 1' || key !== 'STAND #')
                .map((headCell: string, index) => (
                  <TableCell key={index}>
                    <TableSortLabel
                      active={orderBy === columnMapping[headCell]}
                      direction={
                        orderBy === columnMapping[headCell] ? order : 'asc'
                      }
                      onClick={() =>
                        handleRequestSort(columnMapping[headCell] as string)
                      }
                    >
                      {headCell}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data.location.locationRc}</TableCell>
                <TableCell>{data.location.locationName}</TableCell>
                {stands !== 'Stand 1' && <TableCell>{data.stand}</TableCell>}
                <TableCell>
                  {data.name} {data.validationRate}
                </TableCell>
                <TableCell>{data.total}</TableCell>
                <TableCell>{data.totalRevenue.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ValidationSummaryReportTable
