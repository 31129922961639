import React, { useState, useEffect } from 'react'
import {
  Button,
  Box,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  IconButton,
  CircularProgress,
  Typography,
} from '@mui/material'
import { CBox } from 'components'
import RevenueTable from '../RevenueTable'
import ValidationSummaryReportTable from '../RevenueTable/ValidationSummaryReportTable'
import DailyValidationTable from '../RevenueTable/DailyValidationTable'

import DailyReportTable from '../DailyReportTable/DailyReportTable'
import { EntryService } from 'services/Entry.Services'
import { StagingEntryService } from 'services/StagingEntry.Services'
import { GroupedLocation } from 'models/schema' // Adjust import based on your project structure
import { useDataContext } from 'context/DataContext'
import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'

const RevenueData = ({ locations }: { locations: GroupedLocation[] }) => {
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([])
  const [selectedLocationNames, setSelectedLocationNames] = useState<string[]>(
    []
  ) // Track location names
  const [report, setReport] = useState<'Daily' | 'Summary'>('Daily')
  const [stands, setStands] = useState('')
  const [tableData, setTableData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [isValidationReport, setIsValidationReport] = useState(false)
  const [triggerExport, setTriggerExport] = useState(false)

  const { dates } = useDataContext()
  const handleLocationChange = (event: any) => {
    const {
      target: { value },
    } = event

    const selectedLocationNames =
      typeof value === 'string' ? value.split(',') : value

    // Find matching locations and extract their IDs
    const matchingLocations = locations.filter((location) =>
      selectedLocationNames.includes(location.locationName)
    )

    const selectedIds = matchingLocations.flatMap((location) =>
      location.stands.map((stand) => stand.id)
    )

    setSelectedLocationIds(selectedIds) // Set the selected IDs
    setSelectedLocationNames(selectedLocationNames) // Set the selected names for display
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select all locations
      const allNames = locations.map((location) => location.locationName)
      const allIds = locations.flatMap((location) =>
        location.stands.map((stand) => stand.id)
      )

      setSelectedLocationIds(allIds) // Use stand IDs for API call
      setSelectedLocationNames(allNames) // Use location names for display
    } else {
      // Deselect all
      setSelectedLocationIds([])
      setSelectedLocationNames([])
    }
  }

  const handleClearSelection = (event: React.MouseEvent) => {
    event.stopPropagation()
    setSelectedLocationIds([])
    setSelectedLocationNames([])
  }

  const handleReportChange = (event: any) => {
    setReport(event.target.value)
  }

  const handleExportClick = () => {
    setTriggerExport(true) // Trigger export in child
  }

  const onExportComplete = () => {
    setTriggerExport(false) // Reset trigger after export
  }

  const handleStandsChange = (event: any) => {
    setStands(event.target.value)
  }
  const handleValidationClick = () => {
    //if true set false and vice versa
    setIsValidationReport(!isValidationReport)
    // setIsValidationReport(true);
    // fetchReportData();
  }

  const fetchReportData = async () => {
    setLoading(true)
    try {
      const startDate = dates.startDate
      const endDate = dates.endDate

      const data = await EntryService.getRevenueReport(
        selectedLocationIds,
        startDate,
        endDate,
        report,
        isValidationReport
      )
      setTableData(data)
    } catch (error) {
      setTableData([])
      console.error('Error fetching report data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedLocationIds.length > 0) {
      fetchReportData()
    } else {
      setTableData([])
    }
  }, [selectedLocationIds, report, dates, isValidationReport])
  return (
    <React.Fragment>
      <Box>
        <CBox
          jsb
          as
          wrp
          px={{ xs: 0, md: 4 }}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          {/* Dropdown for Select Location */}
          <Select
            multiple
            displayEmpty
            value={selectedLocationNames}
            onChange={handleLocationChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span>Select Location</span>
              }
              if (selected.length > 1) {
                return (
                  <Box display='flex' alignItems='center'>
                    <span>Locations Selected</span>
                    <Box
                      display='flex'
                      gap={'3px'}
                      alignItems='center'
                      bgcolor={'#0A9959'}
                      borderRadius={'5px'}
                    >
                      <Box
                        ml={1}
                        px={'3px'}
                        py={0.5}
                        color='white'
                        borderRadius={1}
                      >
                        {selected.length}
                      </Box>
                      <IconButton onClick={handleClearSelection} size='small'>
                        <img src='/assetsOaks/icon/cancel.svg' alt='Cancel ' />
                      </IconButton>
                    </Box>
                  </Box>
                )
              }
              return selected.join(', ') // Show selected names
            }}
            sx={{
              borderRadius: '7px',
              height: '51px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Default border color
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color when focused
              },
            }}
          >
            <MenuItem value='all'>
              <Checkbox
                checked={selectedLocationNames.length === locations.length}
                onChange={handleSelectAll}
              />
              <ListItemText primary='Select All' />
            </MenuItem>
            {locations.map((location) => (
              <MenuItem
                key={location.locationName}
                value={location.locationName}
              >
                <Checkbox
                  checked={selectedLocationNames.includes(
                    location.locationName
                  )}
                />
                <ListItemText
                  primary={`${location.locationRc} - ${location.locationName}`}
                />
              </MenuItem>
            ))}
          </Select>

          {/* Dropdown for Summary Report */}
          <Select
            value={report}
            onChange={handleReportChange}
            displayEmpty
            sx={{
              minWidth: 227,
              borderRadius: '7px',
              height: '51px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Default border color
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color when focused
              },
            }}
          >
            <MenuItem value='Daily'>Daily Report</MenuItem>
            <MenuItem value='Summary'>Summary Report</MenuItem>
          </Select>
          <Select
            value={stands}
            onChange={handleStandsChange}
            displayEmpty
            sx={{
              minWidth: 227,
              borderRadius: '7px',
              height: '51px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Default border color
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color when focused
              },
            }}
          >
            <MenuItem value=''>
              <span>Show Stands</span>
            </MenuItem>
            <MenuItem value='Stand 1'>Hide Stands</MenuItem>
          </Select>

          {/* Buttons */}
          <Button
            variant='contained'
            onClick={handleValidationClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#0A9959',
              height: '40px',
              color: '#fff',
              marginLeft: 'auto', // This pushes the buttons to the right
            }}
          >
            <img src='/assetsOaks/icon/right.svg' alt='plus' />
            <span style={{ marginLeft: '10px' }}>
              {isValidationReport ? 'Stop Validation' : 'Run Validation'}
            </span>
          </Button>

          <Button
            variant='contained'
            onClick={handleExportClick}
            sx={{
              display: 'flex',
              height: '40px',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#0A9959',
              color: '#fff',
            }}
          >
            <Box
              component='img'
              src='/assetsOaks/icon/dowload.svg'
              alt='export'
              sx={{ width: '19px', height: '19px', objectFit: 'contain' }}
            />
            Export
          </Button>
        </CBox>
      </Box>

      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' mt={4}>
          <CircularProgress />
        </Box>
      ) : isValidationReport ? (
        report === 'Daily' ? (
          <DailyValidationTable
            validationData={tableData}
            stands={stands}
            triggerExport={triggerExport}
            onExportComplete={onExportComplete}
          />
        ) : (
          <ValidationSummaryReportTable
            validationData={tableData}
            stands={stands}
            triggerExport={triggerExport}
            onExportComplete={onExportComplete}
          />
        )
      ) : report === 'Daily' ? (
        <DailyReportTable
          tableData={tableData}
          stands={stands}
          triggerExport={triggerExport}
          onExportComplete={onExportComplete}
        />
      ) : (
        <RevenueTable
          tableData={tableData}
          stands={stands}
          triggerExport={triggerExport}
          onExportComplete={onExportComplete}
        />
      )}
    </React.Fragment>
  )
}

export default RevenueData
