import { LoginForm, AuthLayout } from 'modules/Authentication/components'

type Props = {}

const LoginContainer = (props: Props) => {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  )
}

export default LoginContainer
