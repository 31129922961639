import { firestore } from 'libs/firebase/@firebase'
import { COLLECTIONS } from 'constant'
import { format } from 'date-fns'
import { Location, Admin, Entry } from 'models/schema'
import { getDocumentById, subscribeToCollection } from 'utils/firebaseUtils'
import {
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  collection,
  FirestoreError,
  where,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore'
import { getDocs, query, orderBy, Timestamp } from 'firebase/firestore'
import { start } from 'repl'

class StagingEntries {
  toUTCTimestamp = (date: Date) => {
    return Timestamp.fromDate(
      new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds(),
          date.getUTCMilliseconds()
        )
      )
    )
  }

  getAllEntries = (
    startDate: Date,
    endDate: Date,
    locationIds: string[],
    role: string,
    dataCallback: (entries: Entry[]) => void,
    errorCallback: (error: FirestoreError) => void
  ): (() => void) => {
    const entriesCollectionRef = collection(firestore, COLLECTIONS.ENTRIES)

    const adjustedStartDate = new Date(startDate)
    adjustedStartDate.setHours(0, 0, 0, 0) // Start of the day
    const adjustedEndDate = new Date(endDate)
    adjustedEndDate.setHours(23, 59, 59, 999) // End of the day

    // Handle case where locationIds is empty and role is not "Admin"
    if (role !== 'Admin' && locationIds.length === 0) {
      dataCallback([]) // If there are no locations for a non-admin user, return an empty array
      return () => {} // Return a no-op function
    }

    // Construct the Firestore query conditionally based on the role
    let entriesQuery
    if (role === 'Admin') {
      // For Admin, query all entries where the date range overlaps with the filter range
      entriesQuery = query(
        entriesCollectionRef,
        where('endDate', '>=', Timestamp.fromDate(adjustedStartDate)), // Entry ends after or on the filter start date
        where('endDate', '<=', Timestamp.fromDate(adjustedEndDate)), // Entry ends before or on the filter end date
        where('startDate', '<=', Timestamp.fromDate(adjustedEndDate)) // Entry starts before or on the filter end date
      )
    } else {
      // For non-admins, query entries only for specific locationIds whose date ranges overlap
      entriesQuery = query(
        entriesCollectionRef,
        where('endDate', '>=', Timestamp.fromDate(adjustedStartDate)), // Entry ends after or on the filter start date
        where('endDate', '<=', Timestamp.fromDate(adjustedEndDate)), // Entry ends before or on the filter end date
        where('startDate', '<=', Timestamp.fromDate(adjustedEndDate)), // Entry starts before or on the filter end date
        where('locationId', 'in', locationIds) // Only include specific locationIds
      )
    }

    // Set up Firestore real-time subscription using onSnapshot
    const unsubscribe = onSnapshot(
      entriesQuery,
      async (snapshot) => {
        const entries: Entry[] = []

        // Loop over each document in snapshot and fetch related location data
        for (const entryDoc of snapshot.docs) {
          const entryData = entryDoc.data() as Entry
          entryData.id = entryDoc.id

          // Fetch location data using locationId from the entry
          const locationDocRef = doc(
            firestore,
            COLLECTIONS.LOCATIONS,
            entryData.locationId
          )
          const locationDoc = await getDoc(locationDocRef)

          if (locationDoc.exists()) {
            entryData.location = {
              id: locationDoc.id,
              ...locationDoc.data(),
            } as Location
          }

          entries.push(entryData) // Append the entry with location data
        }

        dataCallback(entries) // Invoke the callback with the fetched data
      },
      (error) => {
        errorCallback(error) // Handle errors
      }
    )

    // Return the unsubscribe function
    return unsubscribe
  }

  getAllEntries2 = (
    startDate: Date,
    endDate: Date,
    locationIds: string[],
    role: string,
    dataCallback: (entries: Entry[]) => void,
    errorCallback: (error: FirestoreError) => void
  ): (() => void) => {
    const entriesCollectionRef = collection(firestore, COLLECTIONS.ENTRIES)

    const adjustedStartDate = new Date(startDate)
    // adjustedStartDate.setUTCHours(0, 0, 0, 0); // Start of the day
    adjustedStartDate.setHours(0, 0, 0, 0)
    const adjustedEndDate = new Date(endDate)
    // adjustedEndDate.setUTCHours(23, 59, 59, 999); // End of the day
    adjustedEndDate.setHours(23, 59, 59, 999)

    // Handle case where locationIds is empty and role is not "Admin"
    if (role !== 'Admin' && locationIds.length === 0) {
      // If there are no locations for a non-admin user, return an empty array
      dataCallback([])
      return () => {} // Return a no-op function
    }

    // Construct the Firestore query conditionally based on the role
    let entriesQuery
    if (role === 'Admin') {
      // For Admin, query all entries within the date range
      entriesQuery = query(
        entriesCollectionRef,
        where('startDate', '>=', Timestamp.fromDate(adjustedStartDate)),
        where('startDate', '<=', Timestamp.fromDate(adjustedEndDate)),
        where('endDate', '<=', Timestamp.fromDate(adjustedEndDate))
      )
    } else {
      // For non-admins, query entries only for specific locationIds within the date range
      entriesQuery = query(
        entriesCollectionRef,
        where('startDate', '>=', Timestamp.fromDate(adjustedStartDate)),
        where('startDate', '<=', Timestamp.fromDate(adjustedEndDate)),
        where('endDate', '<=', Timestamp.fromDate(adjustedEndDate)), // New condition
        where('locationId', 'in', locationIds)
      )
    }

    // Set up Firestore real-time subscription using onSnapshot
    const unsubscribe = onSnapshot(
      entriesQuery,
      async (snapshot) => {
        const entries: Entry[] = []

        // Loop over each document in snapshot and fetch related location data
        for (const entryDoc of snapshot.docs) {
          const entryData = entryDoc.data() as Entry
          entryData.id = entryDoc.id

          // Fetch location data using locationId from the entry
          const locationDocRef = doc(
            firestore,
            COLLECTIONS.LOCATIONS,
            entryData.locationId
          )
          const locationDoc = await getDoc(locationDocRef)

          if (locationDoc.exists()) {
            entryData.location = {
              id: locationDoc.id,
              ...locationDoc.data(),
            } as Location
          }

          entries.push(entryData) // Append the entry with location data
        }

        dataCallback(entries) // Invoke the callback with the fetched data
      },
      (error) => {
        errorCallback(error) // Handle errors
      }
    )

    // Return the unsubscribe function
    return unsubscribe
  }

  // Method to update an existing admin
  updateEntry = async (id: string, data: any): Promise<void> => {
    try {
      const entryDocRef = doc(firestore, COLLECTIONS.ENTRIES, id)

      // Extract users from data and remove it from the data object
      const { users, ...entryData } = data

      // Update the location without the users array
      await setDoc(entryDocRef, entryData, { merge: true })

      console.log('Entry updated successfully')
    } catch (error) {
      console.error('Error updating location:', error)
      throw error
    }
  }

  updateEntriesStatus = async (
    entries: Entry[],
    status: string
  ): Promise<void> => {
    try {
      // Loop through each entry id
      const promises = entries.map(async (entry) => {
        const entryDocRef = doc(firestore, COLLECTIONS.ENTRIES, entry.id)

        // Update the status of the entry
        await setDoc(entryDocRef, { status }, { merge: true })
      })

      // Wait for all the updates to complete
      await Promise.all(promises)

      console.log('All entries updated successfully')
    } catch (error) {
      console.error('Error updating entries status:', error)
      throw error
    }
  }
  checkEntryExist = async (
    startDate: Timestamp,
    endDate: Timestamp,
    locationId: string
  ): Promise<any | null> => {
    try {
      const entriesCollectionRef = collection(firestore, COLLECTIONS.ENTRIES)

      // Query to get all entries for the provided locationId
      const entriesQuery = query(
        entriesCollectionRef,
        where('locationId', '==', locationId)
      )

      // Fetch the query result
      const querySnapshot = await getDocs(entriesQuery)

      // Loop through the entries and check for overlapping date ranges
      for (const doc of querySnapshot.docs) {
        const entry = doc.data()

        const entryStartDate = entry.startDate as Timestamp
        const entryEndDate = entry.endDate as Timestamp

        // Check if the date ranges overlap
        const isOverlap =
          startDate.toDate() <= entryEndDate.toDate() &&
          endDate.toDate() >= entryStartDate.toDate()

        // If there is an overlap, return the existing entry
        if (isOverlap) {
          return { id: doc.id, ...entry } // Return the found entry
        }
      }

      // If no overlapping entry is found, return null
      return null
    } catch (error) {
      console.error('Error checking entry existence:', error)
      throw error
    }
  }

  deleteEntry = async (id: string): Promise<void> => {
    try {
      // Step 1: Delete the main entry document
      const entryDocRef = doc(firestore, COLLECTIONS.ENTRIES, id)
      await deleteDoc(entryDocRef)
      console.log(`Entry with ID ${id} has been deleted.`)

      const collectionsToDelete = [
        COLLECTIONS.CARS,
        COLLECTIONS.VIP_COUNTS,
        COLLECTIONS.VALIDATIONS,
        COLLECTIONS.TIPS,
        COLLECTIONS.TOTAL_TIPS_PER_DAY,
        COLLECTIONS.CC_TIPS_PAID,
        COLLECTIONS.CREDIT_CARDS,
        COLLECTIONS.DEPOSIT_AMOUNT,
        COLLECTIONS.SURCHARGES,
        COLLECTIONS.BLUE_BAR,
        COLLECTIONS.CASH_TURN_IN,
        COLLECTIONS.REVENUE,
        COLLECTIONS.GROSS_REVENUE,
      ]

      for (const collectionName of collectionsToDelete) {
        const querySnapshot = await getDocs(
          query(
            collection(firestore, collectionName),
            where('entryId', '==', id)
          )
        )
        for (const docSnap of querySnapshot.docs) {
          await deleteDoc(docSnap.ref)
        }
      }

      console.log(`All related documents for entry ID ${id} have been deleted.`)
    } catch (error) {
      console.error('Error deleting entry and its related data:', error)
      throw error
    }
  }
  getEntry = async (entryId: string): Promise<any> => {
    try {
      // Fetch the main entry document by entryId
      const entryDocRef = doc(firestore, COLLECTIONS.ENTRIES, entryId)
      const entrySnapshot = await getDoc(entryDocRef)

      if (!entrySnapshot.exists()) {
        throw new Error(`Entry with ID ${entryId} does not exist.`)
      }

      const entryData = entrySnapshot.data()

      // Define the structure for relatedData with the correct typing
      const relatedData: { [key: string]: any[] } = {} // Use a string key and an array of any for values

      // Fetch data from related collections based on entryId
      const collectionsToFetch = [
        COLLECTIONS.CARS,
        COLLECTIONS.VIP_COUNTS,
        COLLECTIONS.VALIDATIONS,
        //COLLECTIONS.TIPS,
        // COLLECTIONS.TOTAL_TIPS_PER_DAY,
        // COLLECTIONS.CC_TIPS_PAID,
        // COLLECTIONS.CREDIT_CARDS,
        // COLLECTIONS.DEPOSIT_AMOUNT,
        // COLLECTIONS.SURCHARGES,
        // COLLECTIONS.BLUE_BAR,
        //COLLECTIONS.CASH_TURN_IN,
        //  COLLECTIONS.REVENUE,
        //COLLECTIONS.GROSS_REVENUE,
        COLLECTIONS.ENTRY_CALCULATIONS,
      ]

      for (const collectionName of collectionsToFetch) {
        const collectionRef = collection(firestore, collectionName)
        const collectionQuery = query(
          collectionRef,
          where('entryId', '==', entryId)
        )
        const querySnapshot = await getDocs(collectionQuery)

        // Populate the relatedData object with the collection's documents
        relatedData[collectionName] = querySnapshot.docs.map((doc) =>
          doc.data()
        )
      }

      // Return the main entry data along with all related data from the collections
      return {
        entryId,
        entryData,
        relatedData,
      }
    } catch (error) {
      console.error('Error fetching entry and related data:', error)
      throw error
    }
  }

  //write function to get validations data by entry id
  getValidationsData = async (entryId: string): Promise<any> => {
    try {
      const validationsCollectionRef = collection(
        firestore,
        COLLECTIONS.STAGING_VALIDATIONS
      )
      const q = query(validationsCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)

      const validationsData = querySnapshot.docs.map((doc) => doc.data())

      return validationsData
    } catch (error) {
      console.error('Error fetching validations data:', error)
      throw error
    }
  }
  bindEntriesValidations2 = async (
    entries: any[],
    startDate: Date,
    endDate: Date,
    type = 'Daily'
  ) => {
    // Ensure entries is defined and is an array
    if (!Array.isArray(entries)) {
      throw new Error('Entries is not an array or is undefined.')
    }

    const entriesWithValidations = await Promise.all(
      entries.map(async (entry) => {
        try {
          const validations = await this.getValidationsData(entry.id)

          // Ensure validations is defined and is an array
          if (!Array.isArray(validations)) {
            console.warn(
              `Validations for entry ID ${entry.id} is not an array.`
            )
            return [] // Return an empty array for this entry
          }

          // Filter and map validations data
          return validations.map((validation: any) => {
            // Filter dailyValidations by date range
            const filteredDailyValidations = validation.values.filter(
              (dailyValidation: any) => {
                const validationDate = dailyValidation.date.toDate() // Convert to JavaScript Date
                return validationDate >= startDate && validationDate <= endDate
              }
            )

            // Return validation object with filtered dailyValidations
            return {
              location: entry.location,
              stand: entry.stand,
              id: entry.id,
              total: validation.total,
              totalRevenue: validation.totalRevenue,
              name: validation.name,
              validationRate: validation.validationRate,
              dailyValidations: filteredDailyValidations,
            }
          })
        } catch (error) {
          console.error(
            `Error fetching validations for entry ID ${entry.id}:`,
            error
          )
          return [] // Return an empty array if there's an error
        }
      })
    )

    // Flatten the array to get a single-level array with all validation data
    return entriesWithValidations.flat()
  }
  bindEntriesValidations = async (
    entries: any[],
    startDate: Date,
    endDate: Date,
    type = 'Daily'
  ) => {
    // Ensure entries is defined and is an array
    if (!Array.isArray(entries)) {
      throw new Error('Entries is not an array or is undefined.')
    }

    // Fetch all validations for all entries
    const allValidations = await Promise.all(
      entries.map(async (entry) => {
        try {
          const validations = await this.getValidationsData(entry.id)

          // Ensure validations is defined and is an array
          if (!Array.isArray(validations)) {
            console.warn(
              `Validations for entry ID ${entry.id} is not an array.`
            )
            return [] // Return an empty array for this entry
          }

          return validations.map((validation: any) => ({
            location: {
              ...entry.location,
              locationRc: parseInt(entry.location?.locationRc, 10), // Convert locationRc to integer
            },
            stand: entry.stand,
            id: entry.id,
            total: validation.total,
            totalRevenue: validation.totalRevenue,
            name: validation.name,
            validationRate: validation.validationRate,
            dailyValidations: validation.values.map((dailyValidation: any) => ({
              ...dailyValidation,
              validationDate: dailyValidation.date.toDate(),
              dayRevenue: dailyValidation.dayRevenue ?? 0,
            })),
          }))
        } catch (error) {
          console.error(
            `Error fetching validations for entry ID ${entry.id}:`,
            error
          )
          return [] // Return an empty array if there's an error
        }
      })
    )

    // Flatten all validations into a single array
    const flatValidations = allValidations.flat()

    if (type === 'Daily') {
      // Filter by date range for daily report
      return flatValidations.map((validation: any) => ({
        ...validation,
        dailyValidations: validation.dailyValidations.filter(
          (dailyValidation: any) => {
            const validationDate = dailyValidation.validationDate
            return validationDate >= startDate && validationDate <= endDate
          }
        ),
      }))
    } else if (type === 'Summary') {
      // Group by stand, name, and validation rate
      const groupMap: { [key: string]: any } = {}

      flatValidations.forEach((validation: any) => {
        const filteredDailyValidations = validation.dailyValidations.filter(
          (dailyValidation: any) => {
            const validationDate = dailyValidation.validationDate
            return validationDate >= startDate && validationDate <= endDate
          }
        )

        const groupKey = `${validation.stand}-${validation.name}-${validation.validationRate}`

        // Initialize the group if it doesn't exist
        if (!groupMap[groupKey]) {
          groupMap[groupKey] = {
            location: validation.location,
            stand: validation.stand,
            name: validation.name,
            validationRate: validation.validationRate,
            total: 0,
            totalRevenue: 0,
          }
        }

        // Aggregate totals and revenues from filteredDailyValidations
        filteredDailyValidations.forEach((dailyValidation: any) => {
          groupMap[groupKey].total += dailyValidation.value || 0
          groupMap[groupKey].totalRevenue += dailyValidation.dayRevenue || 0
        })
      })

      // Convert the aggregated data into an array
      return Object.values(groupMap)
    }

    return []
  }

  getRevenueReport = async (
    locationIds: string[],
    startDate: Date,
    endDate: Date,
    type: 'Daily' | 'Summary',
    isValidationReport: boolean
  ) => {
    try {
      if (!locationIds || locationIds.length === 0) {
        throw new Error('No locations provided for the report.')
      }

      const adjustedStartDate = new Date(startDate)
      adjustedStartDate.setHours(0, 0, 0, 0)

      const adjustedEndDate = new Date(endDate)
      adjustedEndDate.setHours(23, 59, 59, 999)

      const entriesCollectionRef = collection(
        firestore,
        COLLECTIONS.STAGING_ENTRIES
      )

      const batchSize = 30
      const batchedQueries = []
      for (let i = 0; i < locationIds.length; i += batchSize) {
        const batch = locationIds.slice(i, i + batchSize)
        const q = query(
          entriesCollectionRef,
          where('locationId', 'in', batch),
          where('startDate', '<=', Timestamp.fromDate(adjustedEndDate)),
          where('endDate', '>=', Timestamp.fromDate(adjustedStartDate))
        )
        batchedQueries.push(getDocs(q))
      }

      const querySnapshots = await Promise.all(batchedQueries)
      const entries: { id: string; locationId: string; [key: string]: any }[] =
        []
      querySnapshots.forEach((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const data = doc.data() as { locationId: string }
          entries.push({
            id: doc.id,
            ...data,
          })
        })
      })

      const locationIdsSet = new Set(entries.map((entry) => entry.locationId))

      const locationCollectionRef = collection(firestore, 'locations')
      const locationBatches = []
      for (let i = 0; i < Array.from(locationIdsSet).length; i += batchSize) {
        const batch = Array.from(locationIdsSet).slice(i, i + batchSize)
        const locationQuery = query(
          locationCollectionRef,
          where('__name__', 'in', batch)
        )
        locationBatches.push(getDocs(locationQuery))
      }

      const locationSnapshots = await Promise.all(locationBatches)
      const locationData: Record<string, any> = {}
      locationSnapshots.forEach((snapshot) => {
        snapshot.docs.forEach((doc) => {
          locationData[doc.id] = doc.data()
        })
      })

      const entriesWithLocation = entries.map((entry) => ({
        ...entry,
        location: locationData[entry.locationId] || {},
      }))

      if (isValidationReport) {
        const validationData = await this.bindEntriesValidations(
          entriesWithLocation,
          adjustedStartDate,
          adjustedEndDate,
          type
        )
        return validationData
      }

      if (type === 'Daily') {
        const filteredEntriesWithLocation = entriesWithLocation.map(
          (entry: any) => {
            const filteredDailyStatistics = entry.entryDailyStatistics?.filter(
              (dailyStat: any) => {
                const statDate = dailyStat.date.toDate()
                return (
                  statDate >= adjustedStartDate && statDate <= adjustedEndDate
                )
              }
            )
            return {
              ...entry,
              entryDailyStatistics: filteredDailyStatistics || [],
            }
          }
        )

        return filteredEntriesWithLocation
      }

      // Summary calculation using filteredDailyStatistics
      const aggregatedData: Record<string, any> = {}

      entriesWithLocation.forEach((entry: any) => {
        const filteredDailyStatistics = entry.entryDailyStatistics?.filter(
          (dailyStat: any) => {
            const statDate = dailyStat.date.toDate()
            return statDate >= adjustedStartDate && statDate <= adjustedEndDate
          }
        )

        filteredDailyStatistics?.forEach((dailyStat: any) => {
          const locationId = entry.locationId

          if (!aggregatedData[locationId]) {
            aggregatedData[locationId] = {
              totalCars: 0,
              totalCcTipsPaid: 0,
              totalCreditCard: 0,
              totalCreditCardSurcharge: 0,
              totalDepositAmount: 0,
              totalGross: 0,
              totalRevenue: 0,
              totalTips: 0,
              totalConditinalTips: 0,
              totalblubar: 0,
              totalcalculatedCashTurnIn: 0,
              totalValidationCars: 0,
              totalValidationRevenue: 0,
              stand: '',
              entriesCount: 0,
              location: {
                ...entry.location,
                locationRc: parseInt(entry.location?.locationRc, 10), // Convert locationRc to integer
              },
              // location: entry.location,
            }
          }

          // Aggregate data from filteredDailyStatistics
          aggregatedData[locationId].totalCars += dailyStat.totalCars || 0
          aggregatedData[locationId].totalCcTipsPaid +=
            parseFloat(dailyStat.totalCcTipsPaid) || 0
          aggregatedData[locationId].totalCreditCard +=
            parseFloat(dailyStat.totalCreditCard) || 0
          aggregatedData[locationId].totalCreditCardSurcharge +=
            parseFloat(dailyStat.totalCreditCardSurcharge) || 0
          aggregatedData[locationId].totalDepositAmount +=
            parseFloat(dailyStat.totalDepositAmount) || 0
          aggregatedData[locationId].totalGross +=
            parseFloat(dailyStat.totalGross) || 0
          aggregatedData[locationId].totalRevenue +=
            parseFloat(dailyStat.totalRevenue) || 0
          aggregatedData[locationId].totalTips +=
            parseFloat(dailyStat.totalReceivedTips) || 0
          aggregatedData[locationId].totalblubar +=
            parseFloat(dailyStat.totalBlueBar) || 0
          aggregatedData[locationId].totalcalculatedCashTurnIn +=
            parseFloat(dailyStat.totalCalculatedCashTurnIn) || 0
          aggregatedData[locationId].totalValidationCars +=
            parseFloat(dailyStat.totalValidationCars) || 0
          aggregatedData[locationId].totalValidationRevenue +=
            parseFloat(dailyStat.totalValidationRevenue) || 0
          aggregatedData[locationId].totalConditinalTips +=
            parseFloat(dailyStat.totalConditionalTips) || 0
          aggregatedData[locationId].stand = entry.stand
          aggregatedData[locationId].entriesCount += 1
        })
      })

      return Object.keys(aggregatedData).map((locationId) => ({
        locationId,
        ...aggregatedData[locationId],
      }))
    } catch (error) {
      console.error('Error fetching revenue report:', error)
      throw error
    }
  }

  getRevenueReport2 = async (
    locationIds: string[],
    startDate: Date,
    endDate: Date,
    type: 'Daily' | 'Summary',
    isValidationReport: boolean
  ) => {
    try {
      // Check if locationIds array is empty
      if (!locationIds || locationIds.length === 0) {
        throw new Error('No locations provided for the report.')
      }

      const adjustedStartDate = new Date(startDate)
      adjustedStartDate.setHours(0, 0, 0, 0) // Start of the day without UTC conversion

      const adjustedEndDate = new Date(endDate)
      adjustedEndDate.setHours(23, 59, 59, 999) // End of the day without UTC conversion

      const entriesCollectionRef = collection(firestore, 'entries')
      console.log('startDate1', startDate)
      console.log('startDate', adjustedStartDate)

      // Split locationIds into batches of 30
      const batchSize = 30
      const batchedQueries = []
      for (let i = 0; i < locationIds.length; i += batchSize) {
        const batch = locationIds.slice(i, i + batchSize)
        const q = query(
          entriesCollectionRef,
          where('locationId', 'in', batch),
          where('startDate', '>=', Timestamp.fromDate(adjustedStartDate)),
          where('startDate', '<=', Timestamp.fromDate(adjustedEndDate)),
          where('endDate', '<=', Timestamp.fromDate(adjustedEndDate))
        )
        batchedQueries.push(getDocs(q))
      }

      // Execute all batched queries and merge results
      const querySnapshots = await Promise.all(batchedQueries)
      const entries: { id: string; locationId: string; [key: string]: any }[] =
        []
      querySnapshots.forEach((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const data = doc.data() as { locationId: string }
          entries.push({
            id: doc.id,
            ...data,
          })
        })
      })

      const locationIdsSet = new Set(entries.map((entry) => entry.locationId))

      // Fetch the location details based on the locationIds from the 'locations' collection
      const locationCollectionRef = collection(firestore, 'locations')
      const locationBatches = []
      for (let i = 0; i < Array.from(locationIdsSet).length; i += batchSize) {
        const batch = Array.from(locationIdsSet).slice(i, i + batchSize)
        const locationQuery = query(
          locationCollectionRef,
          where('__name__', 'in', batch)
        )
        locationBatches.push(getDocs(locationQuery))
      }

      const locationSnapshots = await Promise.all(locationBatches)

      // Map location data for easy reference
      const locationData: Record<string, any> = {}
      locationSnapshots.forEach((snapshot) => {
        snapshot.docs.forEach((doc) => {
          locationData[doc.id] = doc.data()
        })
      })

      // Attach location data to each entry
      const entriesWithLocation = entries.map((entry) => ({
        ...entry,
        location: locationData[entry.locationId] || {},
      }))

      // If validation report is selected, bind validations to entries
      if (isValidationReport) {
        const validationData = await this.bindEntriesValidations(
          entriesWithLocation,
          startDate,
          endDate
        )
        console.log('validationData', validationData)
        return validationData
      }

      if (type === 'Daily') {
        return entriesWithLocation
      }

      // Summary type: Aggregate data by location
      const aggregatedData: Record<string, any> = {}

      entriesWithLocation.forEach((entry: any) => {
        const locationId = entry.locationId

        if (!aggregatedData[locationId]) {
          aggregatedData[locationId] = {
            totalCars: 0,
            totalCcTipsPaid: 0,
            totalCreditCard: 0,
            totalCreditCardSurcharge: 0,
            totalDepositAmount: 0,
            totalGross: 0,
            totalRevenue: 0,
            totalTips: 0,
            totalConditinalTips: 0,
            totalblubar: 0,
            totalcalculatedCashTurnIn: 0,
            totalValidationCars: 0,
            totalValidationRevenue: 0,
            stand: '',
            entriesCount: 0,
            location: entry.location, // Attach location data to aggregated data
          }
        }

        aggregatedData[locationId].totalCars += entry.totalCars || 0
        aggregatedData[locationId].totalCcTipsPaid += entry.totalCcTipsPaid || 0
        aggregatedData[locationId].totalCreditCard += entry.totalCreditCard || 0
        aggregatedData[locationId].totalCreditCardSurcharge +=
          entry.totalCreditCardSurcharge || 0
        aggregatedData[locationId].totalDepositAmount +=
          entry.totalDepositAmount || 0
        aggregatedData[locationId].totalGross += entry.totalGross || 0
        aggregatedData[locationId].totalRevenue += entry.totalRevenue || 0
        aggregatedData[locationId].totalTips += entry.totalTipsRevenue || 0
        aggregatedData[locationId].totalblubar += entry.totalblubar || 0
        aggregatedData[locationId].totalcalculatedCashTurnIn +=
          entry.totalcalculatedCashTurnIn || 0
        aggregatedData[locationId].totalValidationCars +=
          entry.totalValidationCars || 0
        aggregatedData[locationId].totalValidationRevenue +=
          entry.totalValidationRevenue || 0
        aggregatedData[locationId].totalConditinalTips +=
          entry.totalConditinalTips || 0
        aggregatedData[locationId].stand = entry.stand

        aggregatedData[locationId].entriesCount += 1
      })

      return Object.keys(aggregatedData).map((locationId) => ({
        locationId,
        ...aggregatedData[locationId],
      }))
    } catch (error) {
      console.error('Error fetching revenue report:', error)
      throw error
    }
  }

  createEntry = async (
    entryData: any,
    validationCounts: any[]
  ): Promise<void> => {
    try {
      // 1. Extract startDate and endDate
      const { startDate, endDate } = entryData

      if (!startDate || !endDate) {
        throw new Error('startDate and endDate are required in entryData.')
      }

      // Convert startDate and endDate to formatted strings
      // Format startDate and endDate without timezone offsets
      console.log(startDate, endDate)
      const startDateStr = format(new Date(startDate), 'yyyy-MM-dd') // e.g., "2024-12-30"
      const endDateStr = format(new Date(endDate), 'yyyy-MM-dd') // e.g., "2024-12-31"

      // Combine startDate and endDate into the desired document ID format
      const documentId = `${startDateStr}-${endDateStr}-${entryData.locationRc}`

      // 2. Create the document reference with a specific ID
      const entryCollectionRef = collection(firestore, COLLECTIONS.ENTRIES)
      const entryDocRef = doc(entryCollectionRef, documentId)

      // 3. Save the main entry
      await setDoc(entryDocRef, entryData)
      console.log('New entry created with ID:', documentId)

      // 4. Store Cars Data
      await this.storeValidationCounts(documentId, validationCounts)

      console.log('All data stored successfully.')
    } catch (error) {
      console.error('Error creating entry:', error)
      throw error
    }
  }

  // Validation Counts
  storeValidationCounts = async (entryId: string, validationCounts: any[]) => {
    try {
      const validationCollectionRef = collection(
        firestore,
        COLLECTIONS.VALIDATIONS
      )

      // Loop over each validation count
      for (const validation of validationCounts) {
        // Query to check if a document with the same entryId and name already exists
        const q = query(
          validationCollectionRef,
          where('entryId', '==', entryId),
          where('name', '==', validation.name),
          where('validationRate', '==', validation.validationRate)
        )

        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          // Document exists, update it
          for (const docSnapshot of querySnapshot.docs) {
            const docRef = doc(validationCollectionRef, docSnapshot.id) // Reference the existing document by ID
            await updateDoc(docRef, { ...validation }) // Use updateDoc to ensure partial updates
          }
        } else {
          // Document does not exist, create a new one
          await addDoc(validationCollectionRef, {
            entryId,
            ...validation,
          })
        }
      }
    } catch (error) {
      console.error('Error storing validation counts:', error)
      throw error
    }
  }

  // Cars Data
  storeCarsData = async (entryId: string, carsData: any) => {
    try {
      const carsCollectionRef = collection(firestore, COLLECTIONS.CARS)
      const q = query(carsCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      const carsDocRef = doc(carsCollectionRef, entryId) // Reference to the document using entryId

      // Add entryId to the carsData object before storing it
      const dataWithEntryId = { ...carsData, entryId }

      // Use setDoc to either update if exists or create if not
      await setDoc(carsDocRef, dataWithEntryId, { merge: true }) // merge: true ensures updates without overwriting existing fields
    } catch (error) {
      console.error('Error storing cars data:', error)
      throw error
    }
  }

  // VIP Counts
  storeVipCounts = async (entryId: string, vipCounts: any[]) => {
    try {
      const vipCollectionRef = collection(firestore, COLLECTIONS.VIP_COUNTS)
      const q = query(vipCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      for (const vipCount of vipCounts) {
        const vipDocRef = doc(vipCollectionRef) // Create a new document for each vipCount

        // Store each vipCount as a separate document
        await setDoc(vipDocRef, {
          entryId,
          rate: vipCount.rate,
          total: vipCount.total,
          totalRevenue: vipCount.totalRevenue,
          values: vipCount.values, // Store values array (with day, date, value)
        })
      }
    } catch (error) {
      console.error('Error storing VIP counts:', error)
      throw error
    }
  }

  storeConditionalTips = async (entryId: string, conditionalTips: any[]) => {
    try {
      const vipCollectionRef = collection(
        firestore,
        COLLECTIONS.CONDITIONAL_TIPS
      )
      const q = query(vipCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      for (const conditionalTip of conditionalTips) {
        const vipDocRef = doc(vipCollectionRef) // Create a new document for each conditionalTip

        // Store each conditionalTip as a separate document
        await setDoc(vipDocRef, {
          entryId,
          rate: conditionalTip.rate,
          rate2: conditionalTip.rate2,
          total: conditionalTip.total,
          totalRevenue: conditionalTip.totalRevenue,
          values: conditionalTip.values, // Store values array (with day, date, value)
        })
      }
    } catch (error) {
      console.error('Error storing conditionalTips:', error)
      throw error
    }
  }

  storeEntryCalculations = async (entryId: string, entryCalculations: any) => {
    try {
      const entryCalculationsCollectionRef = collection(
        firestore,
        COLLECTIONS.ENTRY_CALCULATIONS
      )
      const q = query(
        entryCalculationsCollectionRef,
        where('entryId', '==', entryId)
      )
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(entryCalculationsCollectionRef, {
        entryId,
        ...entryCalculations,
      })
    } catch (error) {
      console.error('Error storing entryCalculations data:', error)
      throw error
    }
  }

  // Tips Data
  storeTipData = async (entryId: string, tipData: any[]) => {
    try {
      // Check and update or create for COLLECTIONS.TIPS
      const tipsCollectionRef = collection(firestore, COLLECTIONS.TIPS)

      // Query to check if a document for entryId already exists in TIPS
      const tipsQuery = query(
        tipsCollectionRef,
        where('entryId', '==', entryId)
      )
      const tipsSnapshot = await getDocs(tipsQuery)

      if (!tipsSnapshot.empty) {
        // Document exists, update the first document found (assuming one per entryId)
        const docRef = doc(tipsCollectionRef, tipsSnapshot.docs[0].id)
        await setDoc(docRef, { ...tipData[0] }, { merge: true }) // Update with merge
      } else {
        // Document does not exist, create a new one
        await addDoc(tipsCollectionRef, {
          entryId,
          ...tipData[0], // Assuming tipData[0] contains the data you want to save
        })
      }
    } catch (error) {
      console.error('Error storing tips data:', error)
      throw error
    }
  }

  // CC Tips Paid
  storeCcTipsPaid = async (entryId: string, ccTipsPaidData: any) => {
    try {
      const ccTipsPaidCollectionRef = collection(
        firestore,
        COLLECTIONS.CC_TIPS_PAID
      )
      const q = query(ccTipsPaidCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(ccTipsPaidCollectionRef, {
        entryId,
        ...ccTipsPaidData,
      })
    } catch (error) {
      console.error('Error storing CC tips paid data:', error)
      throw error
    }
  }

  // Credit Card Data
  storeCreditCardData = async (entryId: string, creditCardData: any) => {
    try {
      const creditCardCollectionRef = collection(
        firestore,
        COLLECTIONS.CREDIT_CARDS
      )
      const q = query(creditCardCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(creditCardCollectionRef, {
        entryId,
        ...creditCardData,
      })
    } catch (error) {
      console.error('Error storing credit card data:', error)
      throw error
    }
  }

  // Deposit Amount Data
  storeDepositAmountData = async (entryId: string, depositData: any) => {
    try {
      const depositCollectionRef = collection(
        firestore,
        COLLECTIONS.DEPOSIT_AMOUNT
      )
      const q = query(depositCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(depositCollectionRef, {
        entryId,
        ...depositData,
      })
    } catch (error) {
      console.error('Error storing deposit data:', error)
      throw error
    }
  }

  // Surcharge Data
  // Surcharge Data
  storeSurchargeData = async (entryId: string, surchargeData: any) => {
    try {
      const surchargeCollectionRef = collection(
        firestore,
        COLLECTIONS.SURCHARGES
      )
      const q = query(surchargeCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(surchargeCollectionRef, {
        entryId,
        ...surchargeData,
      })
    } catch (error) {
      console.error('Error storing surcharge data:', error)
      throw error
    }
  }

  // Blue Bar Data
  storeBlueBarData = async (entryId: string, blueBarData: any) => {
    try {
      const blueBarCollectionRef = collection(firestore, COLLECTIONS.BLUE_BAR)
      const q = query(blueBarCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(blueBarCollectionRef, {
        entryId,
        ...blueBarData,
      })
    } catch (error) {
      console.error('Error storing blue bar data:', error)
      throw error
    }
  }

  // Calculated Cash Turn-In Data
  storeCashTurnInData = async (entryId: string, cashTurnInData: any) => {
    try {
      const cashTurnInCollectionRef = collection(
        firestore,
        COLLECTIONS.CASH_TURN_IN
      )
      //find old document against entry id and delete it
      const q = query(cashTurnInCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(cashTurnInCollectionRef, {
        entryId,
        ...cashTurnInData,
      })
    } catch (error) {
      console.error('Error storing cash turn-in data:', error)
      throw error
    }
  }

  // Revenue Data
  storeRevenueData = async (entryId: string, revenueData: any) => {
    try {
      //find old document against entry id and delete it

      const revenueCollectionRef = collection(firestore, COLLECTIONS.REVENUE)
      const q = query(revenueCollectionRef, where('entryId', '==', entryId))
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(revenueCollectionRef, {
        entryId,
        ...revenueData,
      })
    } catch (error) {
      console.error('Error storing revenue data:', error)
      throw error
    }
  }

  // Gross Revenue Data
  storeGrossRevenueData = async (entryId: string, grossRevenueData: any) => {
    try {
      const grossRevenueCollectionRef = collection(
        firestore,
        COLLECTIONS.GROSS_REVENUE
      )
      const q = query(
        grossRevenueCollectionRef,
        where('entryId', '==', entryId)
      )
      const querySnapshot = await getDocs(q)
      for (const docSnap of querySnapshot.docs) {
        await deleteDoc(docSnap.ref)
      }

      await addDoc(grossRevenueCollectionRef, {
        entryId,
        ...grossRevenueData,
      })
    } catch (error) {
      console.error('Error storing gross revenue data:', error)
      throw error
    }
  }
}

const StagingEntryService = new StagingEntries()

export { StagingEntryService }
