import React, { useEffect } from 'react'
import Papa from 'papaparse'
import { Button } from '@mui/material'

import { LocationService } from 'services/Location.Services'
import { EntryService } from 'services/Entry.Services' // Import your
import { StagingEntryService } from 'services/StagingEntry.Services'
import { AppLayout } from 'layout'
import { CBox } from 'components'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'

// import _ from 'lodash'
const ImportContainer = () => {
  const processCSV = async () => {
    const csvFilePath = `/assets/entriedatanew.csv` // Update with your file path

    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]

    // try {
    //   const response = await fetch(csvFilePath)
    //   const csvData = await response.text()
    //   // console.log("Fetched CSV Data:", csvData);

    //   Papa.parse(csvData, {
    //     header: true,
    //     skipEmptyLines: true,
    //     complete: async (result: Papa.ParseResult<any>) => {
    //       const rows = result.data
    //       const weeklyEntries = _.groupBy(rows, 'Week#')

    //       for (const week of Object.keys(weeklyEntries)) {
    //         const locationEntries = _.groupBy(weeklyEntries[week], 'Location')

    //         for (const location of Object.keys(locationEntries)) {
    //           const weekentry = _.sortBy(locationEntries[location], (entry) =>
    //             moment.utc(entry['Date'], 'DD/MM/YYYY').toDate()
    //           )

    //           const locationRc = weekentry[0]['Location RC']
    //           const locationName = weekentry[0]['Location']

    //           const stand = weekentry[0]['Stand #']

    //           let totalCars = 0
    //           let totalValidationRevenue = 0
    //           let totalValidationCars = 0
    //           let totalConditinalTips = 0
    //           let totalTips = 0
    //           let totalblubar = 0
    //           let totalCreditCardSurcharge = 0
    //           let totalDepositAmount = 0
    //           let totalCreditCard = 0
    //           let totalCcTipsPaid = 0
    //           let totalcalculatedCashTurnIn = 0
    //           let totalTipsRevenue = 0
    //           let totalRevenue = 0
    //           let totalGross = 0
    //           let sDate = moment
    //             .utc(weekentry[0]['Date'], 'DD/MM/YYYY')
    //             .toDate()
    //           let eDate = moment
    //             .utc(weekentry[weekentry.length - 1]['Date'], 'DD/MM/YYYY')
    //             .toDate()
    //           const entryDailyStatistics = []
    //           const validationDailyStatistics = []
    //           const validationCounts = []
    //           let i = 0
    //           const day = moment.utc(sDate, 'DD/MM/YYYY').day() // Always use UTC day

    //           for (const row of weekentry) {
    //             //find day from date
    //             // Normalize date to UTC before saving
    //             const date = Timestamp.fromDate(
    //               moment.utc(row['Date'], 'DD/MM/YYYY').startOf('day').toDate()
    //             )

    //             const day = moment.utc(row['Date'], 'DD/MM/YYYY').day() // Always use UTC day
    //             const dayName = days[day]
    //             i++

    //             entryDailyStatistics.push({
    //               date: date,
    //               day: dayName,
    //               totalCars: parseInt(row['Car Counts']),
    //               totalTips: parseFloat(row['Tips Received']),
    //               totalReceivedTips: parseFloat(row['Total Tips Received']),
    //               totalConditionalTips: parseFloat(
    //                 row['Conditional Cars x Tip Rate']
    //               ),
    //               totalValidationCars: parseInt(row['Validations Counts']),
    //               totalValidationRevenue: parseFloat(
    //                 row['Validation Count x Val Rate']
    //               ),
    //               totalCreditCard: parseFloat(row['Total Credit Cards']),
    //               totalCcTipsPaid: parseFloat(row['CC Tips Paid on Paycheck']),
    //               totalBlueBar: parseFloat(row['Blue Bar']),
    //               totalCalculatedCashTurnIn: parseFloat(
    //                 row['Calculated Deposit']
    //               ),
    //               totalCreditCardSurcharge: parseFloat(
    //                 row['Credit Card Surcharge']
    //               ),
    //               totalDepositAmount: parseFloat(row['Deposit Amount']),
    //               totalRevenue: parseFloat(row['Cars x Rev Rate']),
    //               totalGrossRevenue:
    //                 parseFloat(row['Cars x Rev Rate']) -
    //                 parseFloat(row['Validation Count x Val Rate']),
    //             })
    //             validationDailyStatistics.push({
    //               date: moment.utc(row['Date'], 'DD/MM/YYYY').toDate(),
    //               day: dayName,
    //               value: parseInt(row['Validations Counts']),
    //               dayRevenue: parseFloat(row['Validation Count x Val Rate']),
    //             })

    //             const revenueRate = parseFloat(row['Revenue Rate'])
    //             const conditionalVIPCars = parseInt(row['Conditional VIP Cars'])
    //             const conditionalVIPRevenueRate = parseFloat(
    //               row['Conditional VIP Revenue Rate']
    //             )
    //             const conditionalTipRate = parseFloat(
    //               row['Conditional Tip Rate']
    //             )
    //             const validationsRate = parseFloat(row['Validation Rate'])

    //             totalCars += parseInt(row['Car Counts'])
    //             totalRevenue += parseFloat(row['Cars x Rev Rate'])
    //             totalConditinalTips += parseFloat(
    //               row['Conditional Cars x Tip Rate']
    //             )
    //             totalValidationCars += parseInt(row['Validations Counts'])
    //             totalValidationRevenue += parseFloat(
    //               row['Validation Count x Val Rate']
    //             )
    //             totalCreditCard += parseFloat(row['Total Credit Cards'])
    //             totalTips += parseFloat(row['Tips Received'])
    //             totalCcTipsPaid += parseFloat(row['CC Tips Paid on Paycheck'])
    //             totalblubar += parseFloat(row['Blue Bar'])
    //             totalcalculatedCashTurnIn += parseFloat(
    //               row['Calculated Deposit']
    //             )
    //             totalCreditCardSurcharge += parseFloat(
    //               row['Credit Card Surcharge']
    //             )
    //             totalDepositAmount += parseFloat(row['Deposit Amount'])
    //           }
    //           totalTipsRevenue = totalTips + totalConditinalTips
    //           totalGross = totalRevenue - totalValidationRevenue
    //           //add total gross revenue to validation counts
    //           validationCounts.push({
    //             name: locationName + ' Validations',
    //             validationRate: parseFloat(weekentry[0]['Validation Rate']),
    //             total: totalValidationCars,
    //             totalRevenue: totalValidationRevenue,
    //             values: validationDailyStatistics,
    //           })

    //           if (!locationRc || !locationName) {
    //             console.warn('Invalid row, skipping:', weekentry)
    //             continue
    //           }

    //           try {
    //             // Check if the location exists
    //             const existingLocations =
    //               await LocationService.searchLocationByRc(locationRc)

    //             let locationId: string | null = null

    //             if (existingLocations) {
    //               locationId = existingLocations.id
    //               // Create entry object
    //               const entryData = {
    //                 locationId,
    //                 locationRc,
    //                 locationName,
    //                 stand,
    //                 status: 'closed',
    //                 startDate: sDate,
    //                 endDate: eDate,
    //                 totalCars,
    //                 totalValidationRevenue,
    //                 totalValidationCars,
    //                 totalConditinalTips,
    //                 totalTips,
    //                 totalblubar,
    //                 totalCreditCardSurcharge,
    //                 totalDepositAmount,
    //                 totalCreditCard,
    //                 totalCcTipsPaid,
    //                 totalcalculatedCashTurnIn,
    //                 totalTipsRevenue,
    //                 totalRevenue,
    //                 totalGross,
    //                 createdAt: new Date(),
    //                 is_import: true,
    //                 entryDailyStatistics,
    //               }

    //               console.log('Creating entry:', validationCounts)
    //               // break;

    //               // Call create entry
    //               await StagingEntryService.createEntry(
    //                 entryData,
    //                 validationCounts
    //               )
    //               console.log(
    //                 `Entry for location ${locationName} created successfully`
    //               )
    //             } else {
    //               console.warn(
    //                 `Location with RC ${locationRc} not found, skipping entry.`
    //               )
    //             }
    //           } catch (error) {
    //             console.error(
    //               `Error processing location RC ${locationRc}:`,
    //               error
    //             )
    //           }
    //         }
    //       }

    //       console.log('Completed processing')
    //     },
    //     error: (error: any) => {
    //       console.error('Error parsing CSV:', error)
    //     },
    //   })
    // } catch (error) {
    //   console.error('Error fetching CSV file:', error)
    // }
  }

  useEffect(() => {
    //   processCSV(); // Automatically process the CSV on component load
  }, [])

  return (
    <AppLayout title='Import Entries'>
      <CBox col height={'100%'} gap={5} px={4}>
        <Button onClick={processCSV}>Reprocess CSV</Button>
      </CBox>
    </AppLayout>
  )
}

export default ImportContainer
