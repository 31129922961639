import React from 'react'
import {
  Box,
  Link,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from '@mui/material'
import { SearchBar } from 'components'

interface RowData {
  email: string
  name: string
  subscriptionType: string
  registrationDate: string
  details: string
}

const createData = (
  email: string,
  name: string,
  subscriptionType: string,
  registrationDate: string,
  details: string
): RowData => {
  return { email, name, subscriptionType, registrationDate, details }
}

// Dummy data
const rows: RowData[] = [
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
  createData(
    'AJohnson@gmail.com',
    'Abigail Johnson',
    'Monthly Active',
    'Jan 9 2024',
    'View Details'
  ),
]

const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  // set(event.target.value);
}

const ManageUserData: React.FC = () => {
  return (
    <React.Fragment>
      <Box>
        <SearchBar
          placeholder='Search User by Email, First or Last Name'
          onChange={handleSearch}
        />
      </Box>
      <Box flex={1} overflow={'auto'} mb={2}>
        <TableContainer component={Paper}>
          <Table
            className='user__table'
            sx={{ minWidth: 650 }}
            aria-label='subscription table'
          >
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Subscription Type</TableCell>
                <TableCell>Registration Date</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Typography>
                      {row.subscriptionType.split(' ')[0]}
                    </Typography>
                    <Typography>
                      {row.subscriptionType.split(' ')[1]}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.registrationDate}</TableCell>
                  <TableCell>
                    <Link href='#' onClick={() => alert('Details clicked')}>
                      {row.details}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  )
}

export default ManageUserData
