import React, { useState } from 'react'
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TableContainer,
  TableSortLabel,
  Button,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { parse, format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { EntryService } from 'services/Entry.Services'
import CommonPopup from 'components/CommonPopup/CommonPopup'
import { useDataContext } from 'context/DataContext'
import { useAuthContext } from 'context/AuthContext'
import dayjs, { Dayjs } from 'dayjs'
import { FormDataTypeEntry } from 'types'
import { useNotification } from '../../../../context/notificationContext'
import moment from 'moment'

type Order = 'asc' | 'desc'
interface EntriesTableProps {
  onEdit: (entry: any) => void
  entries: any[]
}
// const EntriesTable: React.FC = () => {
const EntriesTable: React.FC<EntriesTableProps> = ({ onEdit, entries }) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof any>('locationRc')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupClose, setIsPopupClose] = useState(false)

  const [loading, setLoading] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState<any>(null) // Store the selected entry to delete
  const { selectedEntryData, setSelectedEntryData } = useDataContext()
  const { user } = useAuthContext()
  const { setAlert } = useNotification()
  const handleDelete = (entry: any) => {
    // You can store the entry data to reference in the confirm delete action
    setIsPopupOpen(true)
    setSelectedEntry(entry) // Store the entry to delete
  }
  const handleConfirmDelete = async () => {
    try {
      setLoading(true) // Show a loading state if needed
      if (selectedEntry) {
        await EntryService.deleteEntry(selectedEntry.id) // Use the ID of the selected entry to delete it
        console.log(`Entry with ID ${selectedEntry.id} deleted successfully.`)
        setAlert({
          show: true,
          message: 'Entry deleted successfully!',
          variant: 'success',
        })
      }
      setIsPopupOpen(false)
      setIsPopupClose(true) // Show success confirmation popup
      setLoading(false) // Remove loading
    } catch (error) {
      console.error('Error deleting entry:', error)
      setLoading(false) // Remove loading state
    }
  }

  const formatDate = (date: any) => {
    if (!date) return 'Invalid Date'

    // Convert to UTC and format as MM/DD/YYYY
    return moment.utc(date.seconds * 1000).format('MM/DD/yyyy')
  }
  const handleRequestSort = (property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedData = [...entries].sort((a, b) => {
    let aValue: any = a[orderBy]
    let bValue: any = b[orderBy]

    // Handle nested properties (e.g., location.locationName and location.locationRc)
    if (orderBy === 'locationName') {
      aValue = a.location?.locationName || ''
      bValue = b.location?.locationName || ''
    }

    if (orderBy === 'locationRc') {
      // Ensure locationRc is treated as a number
      aValue = parseInt(a.location?.locationRc, 10) || 0
      bValue = parseInt(b.location?.locationRc, 10) || 0
    }

    // Handle date properties
    if (orderBy === 'date') {
      aValue = a.startDate?.toDate ? a.startDate.toDate() : a.startDate
      bValue = b.startDate?.toDate ? b.startDate.toDate() : b.startDate
    }

    // Numeric comparison
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue
    }

    // String comparison
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue)
    }

    // Date comparison
    if (aValue instanceof Date && bValue instanceof Date) {
      return order === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime()
    }

    return 0 // Default to no sorting
  })

  const getWeekDays = (startDate: Dayjs): { day: string; date: string }[] => {
    const daysOfWeek = []
    for (let i = 0; i < 7; i++) {
      const day = startDate.add(i, 'day')
      daysOfWeek.push({
        day: day.format('dddd'), // Get the name of the day (e.g., "Monday")
        date: day.format('MM/DD/YYYY'), // Get the formatted date
      })
    }
    return daysOfWeek
  }
  const [formData, setFormData] = useState<FormDataTypeEntry>({
    entryId: '',
    location: entries[0]?.location, // Initialize with the first location object
    stand: '',
    selectedDate: dayjs(),
    startDate: dayjs().toISOString(), // Store full ISO timestamp
    endDate: dayjs().add(6, 'day').toISOString(), // Store full ISO timestamp for end date
    status: 'open',
    dateRange: {
      start: dayjs().format('MMM D'), // Only display formatted date
      end: dayjs().add(6, 'day').format('MMM D'), // Only display formatted end date
    },
    weekDays: getWeekDays(dayjs()), // Initialize with the current week's dates
  })

  const handleEdit = (entry: any) => {
    const newStartDate = dayjs(entry.startDate.toDate())
    const locationData = entry.location
    locationData.rates = entry.rates
    locationData.validations = entry.validations
    locationData.conditionalRates = entry.conditionalRates
    locationData.blueBar = entry.blueBar
    locationData.conditionalVip = entry.conditionalVip

    formData.entryId = entry.id
    formData.location = locationData
    formData.stand = entry.stand
    formData.selectedDate = newStartDate
    formData.startDate = newStartDate.toISOString()
    formData.endDate = newStartDate.add(6, 'day').toISOString()
    formData.dateRange.start = newStartDate.format('MMM D')
    formData.dateRange.end = newStartDate.add(6, 'day').format('MMM D')
    formData.weekDays = getWeekDays(newStartDate)

    setSelectedEntryData(formData)
    console.log('Edit Entry', formData)

    onEdit(entry)
  }
  const toggleEntryStatus = async (entry: any) => {
    const newStatus = entry.status === 'open' ? 'closed' : 'open'
    try {
      await EntryService.updateEntriesStatus([entry], newStatus)

      setAlert({
        show: true,
        message: `Entry status updated to ${newStatus}`,
        variant: 'success',
      })
    } catch (error) {
      console.error('Error updating entry status:', error)
    }
  }

  return (
    <React.Fragment>
      <Box flex={1} overflow={'auto'} px={{ xs: '0', md: 4 }} zIndex={10}>
        <TableContainer component={Paper} sx={{ flex: 1 }}>
          <Table className='user__table' sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ bgcolor: '#EAEAEA' }}>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'locationRc'}
                    direction={orderBy === 'locationRc' ? order : 'asc'}
                    onClick={() => handleRequestSort('locationRc')}
                  >
                    Location RC
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'locationName'}
                    direction={orderBy === 'locationName' ? order : 'asc'}
                    onClick={() => handleRequestSort('locationName')}
                  >
                    Location
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'stand'}
                    direction={orderBy === 'stand' ? order : 'asc'}
                    onClick={() => handleRequestSort('stand')}
                  >
                    Stand #
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={() => handleRequestSort('date')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleRequestSort('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'locationName'}
                    direction={orderBy === 'locationName' ? order : 'asc'}
                    onClick={() => handleRequestSort('locationName')}
                  >
                    Edit
                  </TableSortLabel>
                </TableCell>
                {user?.role === 'Admin' && (
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'locationName'}
                      direction={orderBy === 'locationName' ? order : 'asc'}
                      onClick={() => handleRequestSort('locationName')}
                    >
                      Delete
                    </TableSortLabel>
                  </TableCell>
                )}
                {user?.role === 'Admin' && (
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'locationName'}
                      direction={orderBy === 'locationName' ? order : 'asc'}
                      onClick={() => handleRequestSort('locationName')}
                    >
                      Action
                    </TableSortLabel>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* testing */}
              {sortedData.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell>{entry.location?.locationRc}</TableCell>
                  <TableCell>{entry.location?.locationName}</TableCell>
                  <TableCell>{entry.stand}</TableCell>
                  <TableCell>
                    {formatDate(entry.startDate)} - {formatDate(entry.endDate)}
                  </TableCell>

                  <TableCell>{entry.status}</TableCell>
                  <TableCell
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {entry?.status === 'open' && (
                      <Box
                        component='img'
                        src='assetsOaks/icon/edit.svg'
                        alt='export'
                        onClick={() => handleEdit(entry)}
                      ></Box>
                    )}
                  </TableCell>
                  {user?.role === 'Admin' && (
                    <TableCell
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <IconButton onClick={() => handleDelete(entry)}>
                        <img src='/assetsOaks/icon/delete.svg' alt='delete' />
                      </IconButton>
                    </TableCell>
                  )}
                  {user?.role === 'Admin' && (
                    <TableCell>
                      <Button
                        variant='contained'
                        color={
                          entry.status === 'open' ? 'secondary' : 'primary'
                        }
                        sx={{
                          display: 'flex',
                          height: '40px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#0A9959',
                          color: '#fff',
                        }}
                        onClick={() => toggleEntryStatus(entry)}
                      >
                        {entry.status === 'open' ? 'Close Entry' : 'Open Entry'}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <CommonPopup
            open={isPopupOpen}
            title={'Are you sure?'}
            message={`Do you want to delete the entry`}
            confirmText={loading ? 'Deleting...' : 'Yes, delete'}
            cancelText={'No, cancel'}
            onConfirm={handleConfirmDelete}
            onCancel={() => setIsPopupOpen(false)}
          />
        </TableContainer>
      </Box>
    </React.Fragment>
  )
}

export default EntriesTable
