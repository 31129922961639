import { DateRange, FormDataTypeEntry } from 'types'
import React, { useRef, useState } from 'react'
import { Button, Typography, Box, Select, MenuItem, Menu } from '@mui/material'
import { CBox, DateRangePicker } from 'components'

import EntriesTable from '../EntriesTable'
import CreateEntry from '../CreateEntry/CreateEntry'
import CommonPopup from 'components/CommonPopup/CommonPopup'
import { useNavigate } from 'react-router-dom'
import CreateEntryModal from '../CreateEntryModal/CreateEntryModal'
import dayjs, { Dayjs } from 'dayjs'
import { EntryService } from 'services/Entry.Services'
import { useAuthContext } from 'context/AuthContext'
import { useDataContext } from 'context/DataContext'
// Songs data is defined outside the component

const EntriesData = ({
  entries,
  locations,
}: {
  entries: any[]
  locations: any[]
}) => {
  const [location, setLocation] = useState('')
  const navigate = useNavigate()
  const [report, setReport] = useState('')
  const [stands, setStands] = useState('')
  const [isCreatingEntry, setIsCreatingEntry] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState<any>(null) // State to store the entry being edited
  const [entryMode, setEntryMode] = useState<'create' | 'edit'>('create')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupClose, setIsPopupClose] = useState(false)
  const [isPopupCreate, setIsPopupCreate] = useState(false)
  //   const [filteredEntries, setFilteredEntries] = useState(entries);
  const [messageText, setMessageText] = useState('')
  const [confirmMessageText, setConfirmMessageText] = useState('')
  const { user } = useAuthContext()
  const { dates } = useDataContext()
  // Update filteredEntries based on location
  const handleLocationChange = (event: any) => {
    const selectedLocation = event.target.value
    setLocation(selectedLocation)

    // Filter entries using the selectedLocation, not the old location state
    const filteredEntries = entries?.filter((entry) =>
      selectedLocation === ''
        ? true
        : entry.location.locationName === selectedLocation
    )

    // You can then do something with `filteredEntries` if needed (e.g., set state)
    // console.log(filteredEntries);
  }

  const handleCloseAllEntries = () => {
    //get start and end date of entries
    const startDate = dayjs(dates.startDate).format('MM/DD/YYYY')
    const endDate = dayjs(dates.endDate).format('MM/DD/YYYY')
    setMessageText(`from ${startDate} to ${endDate}`)
    setConfirmMessageText(`${startDate} - ${endDate}`)

    setIsPopupOpen(true)
  }

  // const handleLocationChange = (event: any) => {
  //    setLocation(event.target.value);
  // };

  const handleReportChange = (event: any) => {
    setReport(event.target.value)
  }

  const handleStandsChange = (event: any) => {
    setStands(event.target.value)
  }

  const handleCreatePopupEntry = (formData: FormDataTypeEntry) => {
    setEntryMode('create')
    setSelectedEntry(null)
    navigate('/create-entry')
    setIsPopupCreate(false)
  }

  const handleCreatePopupClose = () => {
    setIsPopupCreate(false)
  }

  const handleCreateEntry = () => {
    setEntryMode('create')
    setSelectedEntry(null)
    // setIsCreatingEntry(true);
    setIsPopupCreate(true)
  }
  const handleEditEntry2 = (entry: any) => {
    setEntryMode('edit')
    setSelectedEntry(entry)
    setIsCreatingEntry(true)
  }

  const handleEditEntry = (entry: any) => {
    setEntryMode('edit')
    navigate('/create-entry')
    setSelectedEntry(entry)
    setIsCreatingEntry(true)
  }

  const handleBackToEntries = () => {
    setIsCreatingEntry(false)
  }

  const handleConfirmClose = async () => {
    try {
      await EntryService.updateEntriesStatus(entries, 'closed') // or any other status

      console.log('All entries marked as closed')

      // Close the popup after updating the entries
      setIsPopupOpen(false)
      setIsPopupClose(true)
    } catch (error) {
      console.error('Error closing entries:', error)
    }
  }
  const handleConfirmPopupClose = () => {
    // Logic for closing the entries
    setIsCreatingEntry(false)

    setIsPopupOpen(false)
    setIsPopupClose(false)
  }
  const handleCancelClose = () => {
    // Logic for canceling the action
    console.log('Action canceled.')
    setIsPopupOpen(false)
    setIsCreatingEntry(false)
  }

  return (
    <React.Fragment>
      <Box>
        <CBox
          jsb
          as
          wrp
          px={{ xs: '0px', md: 4 }}
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          pt={{ xs: 4, md: 0 }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          gap={2}
        >
          {/* Dropdown for Select Location */}
          <Select
            value={location}
            onChange={handleLocationChange}
            displayEmpty
            sx={{
              minWidth: { xs: '100%', md: 317 },
              borderRadius: '7px',
              height: '51px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Default border color
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Border color when focused
              },
            }}
          >
            <MenuItem value=''>
              <div>Location: All</div>
            </MenuItem>
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.locationName}>
                {location.locationRc}-{location.locationName}
              </MenuItem>
            ))}
          </Select>
          {/* Buttons */}
          {user?.role === 'Admin' && (
            <Button
              variant='contained'
              onClick={handleCloseAllEntries}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#0A9959',
                height: '40px',
                color: '#fff',
                marginLeft: { xs: 'none', md: 'auto' },
              }}
            >
              <img src='/assetsOaks/icon/cancel.svg' alt='plus' />
              <span style={{ marginLeft: '10px' }}>
                Close all Listed Entries
              </span>
            </Button>
          )}

          <Button
            variant='contained'
            onClick={handleCreateEntry}
            sx={{
              display: 'flex',
              height: '40px',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#0A9959',
              color: '#fff',
            }}
          >
            <Box
              component='img'
              src='/assetsOaks/icon/plusbtn.svg'
              alt='export'
              sx={{
                width: '19px',
                height: '19px',
                objectFit: 'contain',
                marginRight: '7px',
              }}
            />
            Create Entry
          </Button>
        </CBox>
        <Box
          borderTop='2px solid #E0E0E0'
          sx={{
            width: '100%',
            mt: 3,
          }}
        ></Box>
      </Box>

      {/* Conditionally render EntriesTable or CreateEntry */}
      {!isCreatingEntry ? (
        <EntriesTable
          onEdit={handleEditEntry}
          entries={entries.filter((entry) =>
            location === '' ? true : entry.location.locationName === location
          )}
        />
      ) : (
        <CreateEntry
          mode={entryMode}
          entryData={selectedEntry}
          onBack={handleBackToEntries}
        />
      )}

      {/* Reusable Popup */}
      <CommonPopup
        open={isPopupOpen}
        title={'Are you sure?'}
        message={'Do you want to close all entries listed'}
        messageText={messageText}
        confirmText={'Yes, close entries.'}
        cancelText={'No, cancel.'}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
      />
      {/* /// confrim close /// */}
      <CommonPopup
        open={isPopupClose}
        title={'Entries listed from'}
        message={confirmMessageText}
        messageText={' have been closed.'}
        confirmText={'Close'}
        cancelText={''}
        onConfirm={handleConfirmPopupClose}
        onCancel={handleCancelClose}
      />

      {/* /// create entry popup /// */}
      {/* Reusable Popup */}
      <CreateEntryModal
        locations={locations}
        open={isPopupCreate}
        handleClose={handleCreatePopupClose}
        onConfirm={handleCreatePopupEntry}
      />
      {/* <CommonPopup
            open={isPopupCreate}
            title={"A similar entry already exists!"}
            message={" would you like to be redirected to that entry?"}
            messageText={""}
            confirmText={"Yes, please redirect me."}
            cancelText={"No, cancel."}
            onConfirm={handleCreatePopupEntry}
            onCancel={handleCreatePopupClose}
         /> */}
    </React.Fragment>
  )
}

export default EntriesData
