import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress,
} from '@mui/material'
import { daysOfWeek, rowHeaders2, rowHeaders4 } from '../../../../constant/data'
import { FormDataTypeEntry } from 'types'
import { CBox } from 'components'
import { useDataContext } from 'context/DataContext'
import { EntryService } from 'services/Entry.Services'
import { Timestamp } from 'firebase/firestore'
import { useNotification } from '../../../../context/notificationContext'
import { useNavigate } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import { ROUTES } from 'constant'
interface CreateEntryProps {
  mode: 'create' | 'edit' // Mode to distinguish between creation and editing
  entryData?: any // The data of the entry being edited
  onBack: () => void // Function to go back to the EntriesTable
}
const TIP_RATE = 5
const CreateEntry: React.FC<CreateEntryProps> = ({
  mode,
  entryData,
  onBack,
}) => {
  const [loadingEditData, setLoadingEditData] = useState(false)
  const { selectedEntryData, setSelectedEntryData } = useDataContext()
  const [inputValues, setInputValues] = useState<{ [key: string]: number[] }>(
    {}
  )
  const [revenueValues, setRevenueValues] = useState<{ [key: string]: number }>(
    {}
  )
  const [validationValues, setValidationValues] = useState<{
    [key: string]: number[]
  }>({})

  const [validationCarCounts, setValidationCarCounts] = useState<{
    [key: string]: number[]
  }>({})

  const [carCountValues, setCarCountValues] = useState<{
    [key: string]: number
  }>({})
  const [validationSums, setValidationSums] = useState<number[]>(
    Array(7).fill(0)
  )
  const [weeklyValidationSums, setWeeklyValidationSums] = useState<number[]>([])
  const [tipValues, setTipValues] = useState<string[]>(Array(7).fill(0))

  const [totalTipsPerRow, setTotalTipsPerRow] = useState<number[]>([])
  const [ccTipsPaidValues, setCcTipsPaidValues] = useState<string[]>(
    Array(7).fill(0)
  )
  const [creditCardValues, setCreditCardValues] = useState<string[]>(
    Array(7).fill(0)
  ) // Initialize with 7 days
  const [depositAmountValues, setDepositAmountValues] = useState<string[]>(
    Array(7).fill(0)
  ) // Initialize with 7 days
  const [creditCardSurchargeValues, setCreditCardSurchargeValues] = useState<
    string[]
  >(Array(7).fill(0))
  const [blueBarValues, setBlueBarValues] = useState<string[]>(Array(7).fill(0))

  const [totalCarSum, setTotalCarSum] = useState<number>(0)
  const [totalRevenueSum, setTotalRevenueSum] = useState<number>(0)
  const [totalGrossSum, setTotalGrossSum] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const { setAlert } = useNotification()
  const navigate = useNavigate()
  // Recalculate totals whenever input values change
  useEffect(() => {
    updateWeeklyTotals()
  }, [inputValues, validationSums])

  useEffect(() => {
    if (selectedEntryData) {
      const initialValues: { [key: string]: number[] } = {}
      const initialRevenues: { [key: string]: number } = {}
      const initialCarCounts: { [key: string]: number } = {}
      const initialValidationSums: number[] = Array(7).fill(0)
      // const initialTipValues = Array.from({ length: rowHeaders2.length }, () =>
      //   Array(7).fill(0)
      // );

      selectedEntryData?.location?.rates.forEach((rate: number) => {
        initialValues[rate] = Array(7).fill(0)
        initialRevenues[rate] = 0
        initialCarCounts[rate] = 0
      })

      // setInputValues(initialValues);
      // setRevenueValues(initialRevenues);
      // setCarCountValues(initialCarCounts);
      // setValidationSums(initialValidationSums);
      // setTipValues(initialTipValues);
    }
  }, [selectedEntryData])

  // const handleInputChange2 = (
  //   rate: string,
  //   dayIndex: number,
  //   value: number
  // ) => {
  //   const updatedValues = { ...inputValues };
  //   if (!updatedValues[rate]) {
  //     updatedValues[rate] = Array(7).fill(0); // Initialize the 7 days
  //   }

  //   const newValue = isNaN(value) || value === null ? 0 : value;
  //   updatedValues[rate][dayIndex] = newValue; // Correctly set the new value

  //   // Update the inputValues state to reflect changes
  //   setInputValues(updatedValues);

  //   // Update car count and revenue
  //   const updatedRevenue = calculateWeeklyRevenue(rate); // Recalculate revenue
  //   const updatedCarCount = calculateWeeklyCarCount(rate); // Recalculate car count

  //   setRevenueValues((prev) => ({ ...prev, [rate]: updatedRevenue })); // Update revenue state
  //   setCarCountValues((prev) => ({ ...prev, [rate]: updatedCarCount })); // Update car count state
  // };
  const handleInputChange = (rate: string, dayIndex: number, value: number) => {
    const updatedValues = { ...inputValues }
    if (!updatedValues[rate]) {
      updatedValues[rate] = Array(7).fill(0) // Initialize the 7 days
    }

    const newValue = isNaN(value) || value === null ? 0 : value
    updatedValues[rate][dayIndex] = newValue // Correctly set the new value

    // Instead of using the state for calculation, pass the updatedValues
    const updatedRevenue = calculateWeeklyRevenue(rate, updatedValues) // Recalculate revenue
    const updatedCarCount = calculateWeeklyCarCount(rate, updatedValues) // Recalculate car count

    // Update the inputValues state to reflect changes
    setInputValues(updatedValues)

    // Update car count and revenue states with the newly calculated values
    setRevenueValues((prev) => ({ ...prev, [rate]: updatedRevenue }))
    setCarCountValues((prev) => ({ ...prev, [rate]: updatedCarCount }))
  }
  // Function to calculate validation row cars
  const calculateValidationRowCars = (name: string, validationRate: number) => {
    const validationKey = name + validationRate.toString() // Create the key using name and validation rate
    const values = validationCarCounts[validationKey] // Access the car counts using the validation key
    // console.log("values", validationCarCounts);
    return values?.reduce((sum, day) => sum + day, 0) || 0 // Sum the values for this row
  }

  const calculateValidationRowRevenue = (
    name: string,
    validationRate: number
  ) => {
    const validationKey = name + validationRate.toString() // Create the key using name and validation rate
    const values = validationValues[validationKey] // Access the validation values using the validation key
    if (!values) return '0.00'
    const totalRevenue = values.reduce(
      (sum, value) => sum + value * validationRate,
      0
    ) // Multiply by validation rate
    return totalRevenue.toFixed(2) // Return the revenue as a string with 2 decimal places
  }
  // Handle validation input changes
  const handleValidationInputChange = (
    name: string,
    validationRate: number,
    dayIndex: number,
    value: number
  ) => {
    const newValue = isNaN(value) || value === null ? 0 : value

    // Create a copy of validationValues to update
    const updatedValidationValues = { ...validationValues }
    const updatedValidationCarCounts = { ...validationCarCounts }

    const validationKey = name + validationRate.toString() // Create key based on name + validationRate

    if (!updatedValidationValues[validationKey]) {
      updatedValidationValues[validationKey] = Array(7).fill(0) // Initialize 7 days if not present
    }

    if (!updatedValidationCarCounts[validationKey]) {
      updatedValidationCarCounts[validationKey] = Array(7).fill(0) // Initialize 7 days if not present
    }

    // Replace the value (new input value) for validationValues
    updatedValidationValues[validationKey][dayIndex] = newValue

    // Store the raw car count in validationCarCounts without multiplying by the rate
    updatedValidationCarCounts[validationKey][dayIndex] = newValue

    // Update the state with new values
    setValidationValues(updatedValidationValues)
    setValidationCarCounts(updatedValidationCarCounts) // Update the car counts state

    // Recalculate validation sums
    const updatedValidationSums = [...validationSums]

    // Reset the dayIndex value for re-calculation
    updatedValidationSums[dayIndex] = 0

    // Calculate validation sums by multiplying with the rate
    Object.keys(updatedValidationValues).forEach((key) => {
      const validationRateFromKey = parseFloat(key.match(/\d+/)?.[0] || '1') // Extract validation rate
      updatedValidationValues[key].forEach((val, idx) => {
        if (idx === dayIndex) {
          updatedValidationSums[dayIndex] += val * validationRateFromKey // Correct calculation
        }
      })
    })

    // Update the validation sums in state
    setValidationSums(updatedValidationSums)

    updateWeeklyTotals() // If needed, update any weekly totals or other data
  }
  //calculate validations grand total
  const calculateValidationGrandTotal = () => {
    let total = validationSums.reduce((total, sum) => total + sum, 0)
    return total.toFixed(2)
  }
  const calculateDailyRevenue = (rate: number, carCount: number) => {
    return carCount * rate
  }

  const calculateWeeklyRevenue = (
    rate: string,
    values: { [key: string]: number[] }
  ) => {
    const weeklyValues: number[] = values[rate] || []
    return weeklyValues.reduce(
      (total: number, dayValue: number) => total + dayValue * Number(rate),
      0
    )
  }

  const calculateWeeklyCarCount = (
    rate: string,
    values: { [key: string]: number[] }
  ) => {
    const weeklyValues: number[] = values[rate] || []
    return weeklyValues.reduce(
      (total: number, dayValue: number) => total + dayValue,
      0
    )
  }

  // const calculateWeeklyRevenue = (rate: string) => {
  //   return (
  //     inputValues[rate]?.reduce(
  //       (total, count) => total + calculateDailyRevenue(Number(rate), count),
  //       0
  //     ) || 0
  //   );
  // };

  // const calculateWeeklyCarCount = (rate: string) => {
  //   return inputValues[rate]?.reduce((total, count) => total + count, 0) || 0;
  // };

  const calculateTotalCarsPerDay = (dayIndex: number) => {
    return Object.values(inputValues).reduce(
      (total, counts) => total + (counts[dayIndex] || 0),
      0
    )
  }

  const calculateTotalRevenuePerDay = (dayIndex: number) => {
    return Object.keys(inputValues).reduce((total, rate) => {
      const rateValue = Number(rate)
      const carCount = inputValues[rate]?.[dayIndex] || 0
      return total + calculateDailyRevenue(rateValue, carCount)
    }, 0)
  }

  const calculateGrossRevenuePerDay = (dayIndex: number) => {
    return calculateTotalRevenuePerDay(dayIndex) - validationSums[dayIndex] // Add validation sums
  }

  const updateWeeklyTotals = () => {
    const totalCars = Object.values(carCountValues).reduce(
      (acc, val) => acc + val,
      0
    )
    const totalRevenue = Object.values(revenueValues).reduce(
      (acc, val) => acc + val,
      0
    )
    const totalGross =
      totalRevenue - validationSums.reduce((acc, val) => acc + val, 0)

    setTotalCarSum(totalCars)
    setTotalRevenueSum(totalRevenue)
    setTotalGrossSum(totalGross)
  }

  const handleTipsInputChange = (dayIndex: number, value: string) => {
    // Use regex to allow only numbers and one or two decimal places
    if (/^\d*\.?\d{0,2}$/.test(value) || /^\d*\.?\d{3,}$/.test(value)) {
      let roundedValue = value

      // Check if the value has more than two decimal places
      if (value.includes('.') && value.split('.')[1].length > 2) {
        // Round to two decimal places if there are more than two decimals
        roundedValue = parseFloat(value).toFixed(2)
      }

      const updatedTips = [...tipValues]
      updatedTips[dayIndex] = roundedValue // Store the rounded string value if needed
      setTipValues(updatedTips)
    }
  }

  const calculateTotalTipsRevenueForRow = () => {
    const total = tipValues.reduce(
      (sum, value) => sum + parseFloat(value || '0'),
      0
    )
    return Math.round(total * 100) / 100 // Rounds to two decimal places as a number
  }

  // Function to calculate total tips for each row
  // const calculateTotalTipsForRow = (rowIndex: number) => {
  //   return tipValues[rowIndex]?.reduce((sum, tips) => sum + tips, 0) || 0;
  // };

  // Function to calculate total tips revenue for each row

  const handleCcTipsPaidInputChange = (dayIndex: number, value: string) => {
    // Regular expression to allow only numbers and a single decimal point
    if (/^\d*\.?\d{0,2}$/.test(value) || /^\d*\.?\d{3,}$/.test(value)) {
      let roundedValue = value

      // Check if the value has more than two decimal places
      if (value.includes('.') && value.split('.')[1].length > 2) {
        // Round to two decimal places if there are more than two decimals
        roundedValue = parseFloat(value).toFixed(2)
      }

      const updatedValues = [...ccTipsPaidValues]
      updatedValues[dayIndex] = roundedValue // Store the rounded string value if needed
      setCcTipsPaidValues(updatedValues)
    }
  }

  const calculateTotalCcTipsPaid = () => {
    const total = ccTipsPaidValues.reduce(
      (sum, value) => sum + parseFloat(value || '0'),
      0
    )
    return Math.round(total * 100) / 100 // Rounds to two decimal places as a number
  }
  const handleCreditCardInputChange = (dayIndex: number, value: string) => {
    // Regular expression to allow only numbers and a single decimal point
    if (/^\d*\.?\d{0,2}$/.test(value) || /^\d*\.?\d{3,}$/.test(value)) {
      let roundedValue = value

      // Check if the value has more than two decimal places
      if (value.includes('.') && value.split('.')[1].length > 2) {
        // Round to two decimal places if there are more than two decimals
        roundedValue = parseFloat(value).toFixed(2)
      }

      const updatedValues = [...creditCardValues]
      updatedValues[dayIndex] = roundedValue // Store the rounded string value if needed
      setCreditCardValues(updatedValues)
    }
  }

  const calculateTotalCreditCards = () => {
    const total = creditCardValues.reduce(
      (sum, value) => sum + parseFloat(value || '0'),
      0
    )
    return Math.round(total * 100) / 100 // Rounds to two decimal places as a number
  }

  const handleDepositAmountInputChange = (dayIndex: number, value: string) => {
    // Regular expression to allow only numbers and a single decimal point
    if (/^\d*\.?\d{0,2}$/.test(value) || /^\d*\.?\d{3,}$/.test(value)) {
      let roundedValue = value

      // Check if the value has more than two decimal places
      if (value.includes('.') && value.split('.')[1].length > 2) {
        // Round to two decimal places if there are more than two decimals
        roundedValue = parseFloat(value).toFixed(2)
      }

      const updatedValues = [...depositAmountValues]
      updatedValues[dayIndex] = roundedValue // Store the rounded string value if needed
      setDepositAmountValues(updatedValues)
    }
  }

  const calculateTotalDepositAmount = () => {
    const total = depositAmountValues.reduce(
      (sum, value) => sum + parseFloat(value || '0'),
      0
    )
    return Math.round(total * 100) / 100 // Rounds to two decimal places as a number
  }

  const handleCreditCardSurchargeChange = (dayIndex: number, value: string) => {
    // Regular expression to allow only numbers and a single decimal point
    if (/^\d*\.?\d{0,2}$/.test(value) || /^\d*\.?\d{3,}$/.test(value)) {
      let roundedValue = value

      // Check if the value has more than two decimal places
      if (value.includes('.') && value.split('.')[1].length > 2) {
        // Round to two decimal places if there are more than two decimals
        roundedValue = parseFloat(value).toFixed(2)
      }

      const updatedValues = [...creditCardSurchargeValues]
      updatedValues[dayIndex] = roundedValue // Store the rounded string value if needed
      setCreditCardSurchargeValues(updatedValues)
    }
  }

  const handleBlueBarChange = (dayIndex: number, value: string) => {
    // Regular expression to allow only numbers and a single decimal point
    if (/^\d*\.?\d{0,2}$/.test(value) || /^\d*\.?\d{3,}$/.test(value)) {
      let roundedValue = value

      // Check if the value has more than two decimal places
      if (value.includes('.') && value.split('.')[1].length > 2) {
        // Round to two decimal places if there are more than two decimals
        roundedValue = parseFloat(value).toFixed(2)
      }

      const updatedValues = [...blueBarValues]
      updatedValues[dayIndex] = roundedValue // Store the rounded string value if needed
      setBlueBarValues(updatedValues)
    }
  }

  const calculateTotalCreditCardSurcharge = () => {
    const total = creditCardSurchargeValues.reduce(
      (sum, value) => sum + parseFloat(value || '0'),
      0
    )
    return Math.round(total * 100) / 100 // Rounds to two decimal places as a number
  }
  const calculateTotalBlueBar = () => {
    const total = blueBarValues.reduce(
      (sum, value) => sum + parseFloat(value || '0'),
      0
    )
    return Math.round(total * 100) / 100 // Rounds to two decimal places as a number
  }
  const calculateCashTurnInForDay = (dayIndex: number) => {
    // Calculate values for each component in the formula
    const grossRevenue = calculateGrossRevenuePerDay(dayIndex) // Existing function for gross revenue
    const ccTipsPaid = parseFloat(ccTipsPaidValues[dayIndex] || '0') // Convert to float for Tips paid on paycheck
    const creditCardSurcharge = parseFloat(
      creditCardSurchargeValues[dayIndex] || '0'
    ) // Convert to float for Tips paid on paycheck
    const blueBar = parseFloat(blueBarValues[dayIndex] || '0') // Convert to float for Tips paid on paycheck
    const totalCreditCards = parseFloat(creditCardValues[dayIndex] || '0') // Total credit card payments

    // Apply the formula: (Gross Revenue + CC Tips Paid + Credit Card Surcharge) - (Blue Bar + Total Credit Cards)
    return (
      grossRevenue +
      ccTipsPaid +
      creditCardSurcharge -
      (blueBar + totalCreditCards)
    )
  }

  const calculateTotalCashTurnIn = () => {
    return daysOfWeek.reduce((total, _, dayIndex) => {
      return total + calculateCashTurnInForDay(dayIndex) // Reuse the per-day calculation
    }, 0)
  }

  const getWeekDays = (startDate: Dayjs): { day: string; date: string }[] => {
    const daysOfWeek = []
    for (let i = 0; i < 7; i++) {
      const day = startDate.add(i, 'day')
      daysOfWeek.push({
        day: day.format('dddd'), // Get the name of the day (e.g., "Monday")
        date: day.format('MM/DD/YYYY'), // Get the formatted date
      })
    }
    return daysOfWeek
  }

  const fetchEntryData = async (entryId: string) => {
    setLoadingEditData(true)
    try {
      const entryWithRelatedData = await EntryService.getEntry(entryId)
      const newStartDate = dayjs(
        entryWithRelatedData.entryData.startDate.toDate()
      )
      // console.log("New Start Date:", newStartDate);
      const updatedWeekDays = getWeekDays(newStartDate)

      // Copy the selectedEntryData and update the weekDays
      if (selectedEntryData) {
        const updatedEntryData = {
          ...selectedEntryData, // Copy the existing selectedEntryData
          weekDays: updatedWeekDays, // Update the weekDays with the new ones
        }

        setSelectedEntryData(updatedEntryData)
      }
      console.log(
        'Entry data :',
        entryWithRelatedData.relatedData.entryCalculations
      )
      setLoadingEditData(false)

      populateFormData(entryWithRelatedData)
      //now set blue bar values
    } catch (error) {
      setLoadingEditData(false)
      console.error('Error fetching entry:', error)
    }
  }
  const populateFormData = (entryWithRelatedData: any) => {
    const creditCardSurchargeValues =
      entryWithRelatedData.relatedData.entryCalculations[0]?.surcharge?.values
    const blueBarValues =
      entryWithRelatedData.relatedData.entryCalculations[0]?.blueBar?.values
    const creditCardValues =
      entryWithRelatedData.relatedData.entryCalculations[0]?.creditCard?.values
    const tipDataValues =
      entryWithRelatedData.relatedData.entryCalculations[0]?.tipsReceived
        ?.values
    // const tipData = entryWithRelatedData.relatedData.tips || [];
    // Get the values for each field

    const depositAmountValues =
      entryWithRelatedData.relatedData.entryCalculations[0]?.deposit?.values
    const ccTipsPaidValues =
      entryWithRelatedData.relatedData.entryCalculations[0]?.ccTipsPaid?.values

    const initialCcTipsPaidValues: string[] =
      ccTipsPaidValues?.map((entry: any) => entry.value.toString()) ||
      Array(7).fill('0')
    setCcTipsPaidValues(initialCcTipsPaidValues)

    const initialCreditCardValues: string[] =
      creditCardValues?.map((entry: any) => entry.value.toString()) ||
      Array(7).fill(0)
    setCreditCardValues(initialCreditCardValues)

    const initialCreditCardSurchargeValues: string[] =
      creditCardSurchargeValues?.map((entry: any) => entry.value.toString()) ||
      Array(7).fill(0)
    setCreditCardSurchargeValues(initialCreditCardSurchargeValues)

    const initialBlueBarValues: string[] =
      blueBarValues?.map((entry: any) => entry.value.toString()) ||
      Array(7).fill(0)
    setBlueBarValues(initialBlueBarValues)

    const initialDepositAmountValues: string[] =
      depositAmountValues?.map((entry: any) => entry.value.toString()) ||
      Array(7).fill(0)
    setDepositAmountValues(initialDepositAmountValues)

    const initiaTipsValues: string[] =
      tipDataValues?.map((entry: any) => String(entry.value)) ||
      Array(7).fill('0')
    setTipValues(initiaTipsValues)

    const conditionalRates = selectedEntryData?.location?.conditionalRates || []

    // const initialInputValues =
    //   entryWithRelatedData.relatedData.vipCounts[0]?.values;

    const populatedInputValues: { [key: string]: number[] } = {}
    const populatedCarCountValues: { [key: string]: number } = {}
    const populatedRevenueValues: { [key: string]: number } = {}

    // Loop through conditionalRates and populate input values
    conditionalRates.forEach((rate: any, rowIndex: number) => {
      const rateKey = rate.rate1 // Assuming `rate1` is the key

      // const initialInputValues =
      //   entryWithRelatedData.relatedData.vipCounts[rowIndex]?.values;
      //match the rate with the values
      const initialInputValues =
        entryWithRelatedData.relatedData.vipCounts.find(
          (item: any) => item.rate == rateKey
        )?.values || []

      const rateValues = initialInputValues.map(
        (entry: any) => entry.value || 0
      ) // Extract values for each day

      // Set input values, car counts, and revenue
      populatedInputValues[rateKey] = rateValues
      populatedCarCountValues[rateKey] = rateValues.reduce(
        (sum: number, value: number) => sum + value,
        0
      )
      populatedRevenueValues[rateKey] = rateValues.reduce(
        (sum: number, value: number) => sum + value * rateKey,
        0
      )
    })

    // Set the values in state
    setInputValues(populatedInputValues)
    setCarCountValues(populatedCarCountValues)
    setRevenueValues(populatedRevenueValues)

    const rates = selectedEntryData?.location?.rates || []
    const rateInputValues = entryWithRelatedData.relatedData.cars[0].carCounts

    const populatedRateInputValues: { [key: string]: number[] } = {}
    const populatedRateCarCountValues: { [key: string]: number } = {}
    const populatedRateRevenueValues: { [key: string]: number } = {}

    // Loop through rates and populate input values
    rates.forEach((rate: number) => {
      const rateData = rateInputValues.find(
        (item: any) => item.rate == rate.toString()
      ) // Find the matching rate in carCounts

      if (rateData) {
        const rateValues = rateData.values.map((entry: any) => entry.value || 0) // Extract the 'value' from each entry in 'values'

        // Set input values, car counts, and revenue
        populatedRateInputValues[rate] = rateValues
        populatedRateCarCountValues[rate] = rateValues.reduce(
          (sum: number, value: number) => sum + value,
          0
        )
        populatedRateRevenueValues[rate] = rateValues.reduce(
          (sum: number, value: number) => sum + value * rate,
          0
        )
      }
    })

    // Set the values in state
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      ...populatedRateInputValues,
    }))
    setCarCountValues((prevCarCountValues) => ({
      ...prevCarCountValues,
      ...populatedRateCarCountValues,
    }))
    setRevenueValues((prevRevenueValues) => ({
      ...prevRevenueValues,
      ...populatedRateRevenueValues,
    }))
    const validations = selectedEntryData?.location?.validations || []
    const validationInputValues =
      entryWithRelatedData.relatedData.validations || []
    console.log('validationInputValues:', validationInputValues)

    const populatedValidationInputValues: { [key: string]: number[] } = {}
    const populatedValidationCarCountValues: { [key: string]: number[] } = {}
    const populatedValidationSums: number[] = Array(7).fill(0) // Array to store sums for each day
    console.log('validations:', validationInputValues)

    // Loop through validations and populate input values
    validations.forEach((validation: any) => {
      const validationKey = validation.name + validation.validation.toString() // Concatenate name and validation
      const validationData = validationInputValues.find(
        (item: any) =>
          item.name === validation.name &&
          item.validationRate === validation.validation.toString()
      )

      if (validationData) {
        const validationValues = validationData.values.map(
          (entry: any) => entry.value || 0
        ) // Extract values for each day

        //console.log("validationData:", validationData);
        populatedValidationInputValues[validationKey] = validationValues // Use concatenated key
        populatedValidationCarCountValues[validationKey] = validationValues

        // Add to validation sum for each day
        validationValues.forEach((value: number, dayIndex: number) => {
          populatedValidationSums[dayIndex] +=
            value * Number(validation.validation) // Calculate sum based on validation rate
        })
      }
    })

    // console.log(
    //   "populatedValidationInputValues:",
    //   populatedValidationInputValues
    // );
    // Set the values in state
    setValidationValues(populatedValidationInputValues)
    setValidationCarCounts(populatedValidationCarCountValues) // Assuming you're tracking car counts separately
    setValidationSums(populatedValidationSums) // Update validation sums as well
  }
  useEffect(() => {
    if (selectedEntryData?.entryId) {
      fetchEntryData(selectedEntryData.entryId)
    }
  }, [selectedEntryData?.entryId])

  // if (selectedEntryData?.entryId) {
  //   fetchEntryData(selectedEntryData?.entryId);
  // }

  // Function to calculate total tips for a single day
  const calculateTotalTipsForDay = (dayIndex: number) => {
    let totalTipsForDay = 0

    // Add conditional tips for this day
    selectedEntryData?.location?.conditionalRates.forEach(
      (conditionalRate: any) => {
        totalTipsForDay +=
          (inputValues[conditionalRate.rate1]?.[dayIndex] || 0) *
          Number(conditionalRate.rate2)
      }
    )

    // Add tips received for this day, converting tipValues[dayIndex] to a number if it's not already
    totalTipsForDay += Number(tipValues[dayIndex] || 0)

    return totalTipsForDay
  }

  // Function to calculate the grand total tips across all days
  const calculateGrandTotalTips = () => {
    const result = daysOfWeek.reduce((grandTotal, _, dayIndex) => {
      const dayTotal = calculateTotalTipsForDay(dayIndex)
      return grandTotal + Number(dayTotal) // Ensure each day total is treated as a number
    }, 0)

    // Round the result to two decimal places and keep it as a number
    return Math.round(result * 100) / 100
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      // Construct the entry data with additional fields

      // Car Counts (example for each rate)
      const carCounts =
        selectedEntryData?.location?.rates.map(
          (rate: any, rowIndex: number) => ({
            rate,
            values: selectedEntryData?.weekDays?.map(
              (day: any, dayIndex: number) => ({
                day: day.day, // Day (e.g., "Tuesday")
                date: Timestamp.fromDate(new Date(day.date)), // Date in Firebase Timestamp format
                value: inputValues[rate]?.[dayIndex] || 0, // Car count for the day
              })
            ),
            totalCars:
              inputValues[rate]?.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) || 0, // Total for the row
            totalRevenue: revenueValues[rate] || 0, // Total revenue for the row
          })
        ) || []

      // VIP Counts
      const vipCounts =
        selectedEntryData?.location?.conditionalRates.map(
          (vip: any, rowIndex: number) => ({
            rate: vip.rate1,
            values: selectedEntryData?.weekDays?.map(
              (day: any, dayIndex: number) => ({
                day: day.day,
                date: Timestamp.fromDate(new Date(day.date)),
                value: inputValues[vip.rate1]?.[dayIndex] || 0,
              })
            ),
            total:
              inputValues[vip.rate1]?.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) || 0,
            totalRevenue: revenueValues[vip.rate1] || 0,
          })
        ) || []
      // console.log("vipCounts:", vipCounts);
      // return;
      // Validation Counts
      const validationCounts =
        selectedEntryData?.location?.validations.map((validation: any) => {
          const validationKey =
            validation.name + validation.validation.toString() // Concatenate name and validation rate to form the key
          return {
            validationRate: validation.validation,
            name: validation.name,
            values: selectedEntryData?.weekDays?.map(
              (day: any, dayIndex: number) => ({
                day: day.day,
                date: Timestamp.fromDate(new Date(day.date)),
                value: validationValues[validationKey]?.[dayIndex] || 0, // Use the concatenated key here
                dayRevenue:
                  (validationValues[validationKey]?.[dayIndex] || 0) *
                  validation.validation,
              })
            ),
            total:
              validationValues[validationKey]?.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) || 0, // Use the concatenated key here
            totalRevenue:
              validationValues[validationKey]?.reduce(
                (sum: number, val: number, dayIndex: number) =>
                  sum + val * validation.validation, // Multiply each value by the validation rate for that row
                0
              ) || 0,
          }
        }) || []

      // Tips Data
      const tipData = rowHeaders2.map((_, rowIndex: number) => ({
        values: selectedEntryData?.weekDays?.map(
          (day: any, dayIndex: number) => ({
            day: day.day,
            date: Timestamp.fromDate(new Date(day.date)),
            value: tipValues[dayIndex] || 0,
          })
        ),
        totalRevenue: calculateTotalTipsRevenueForRow(),
      }))

      const totalTipsPerDay = selectedEntryData?.weekDays?.map(
        (day: any, dayIndex: number) => ({
          day: day.day, // Day (e.g., "Tuesday")
          date: Timestamp.fromDate(new Date(day.date)), // Convert date to Firebase Timestamp
          total: calculateTotalTipsForDay(dayIndex) || 0, // Total revenue for the day
        })
      )
      const totalTipsReceived = {
        values: totalTipsPerDay, // Revenue data for each day with date
        total: calculateGrandTotalTips(), // Total revenue for the week
      }

      // conditional tips
      const conditionalTips =
        selectedEntryData?.location?.conditionalRates.map(
          (vip: any, rowIndex: number) => ({
            rate: vip.rate1,
            rate2: vip.rate2,
            values: selectedEntryData?.weekDays?.map(
              (day: any, dayIndex: number) => ({
                day: day.day,
                date: Timestamp.fromDate(new Date(day.date)),
                value: inputValues[vip.rate1]?.[dayIndex] * vip.rate2 || 0,
              })
            ),
            total:
              inputValues[vip.rate1]?.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) || 0,
            totalRevenue:
              inputValues[vip.rate1]?.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) * vip.rate2,
          })
        ) || []

      //calculate total conditional tips revenue
      let totalConditinalTips = conditionalTips.reduce(
        (sum, tips) => sum + tips.totalRevenue,
        0
      )

      // CC Tips Paid
      const ccTipsPaidData = {
        values: selectedEntryData?.weekDays?.map(
          (day: any, dayIndex: number) => ({
            day: day.day,
            date: Timestamp.fromDate(new Date(day.date)),
            value: ccTipsPaidValues[dayIndex] || 0,
          })
        ),
        total: calculateTotalCcTipsPaid(),
      }

      // Credit Card Data
      const creditCardData = {
        values: selectedEntryData?.weekDays?.map(
          (day: any, dayIndex: number) => ({
            day: day.day,
            date: Timestamp.fromDate(new Date(day.date)),
            value: creditCardValues[dayIndex] || 0,
          })
        ),
        total: calculateTotalCreditCards(),
      }
      // Deposit Amount Data
      const depositData = {
        values: selectedEntryData?.weekDays?.map(
          (day: any, dayIndex: number) => ({
            day: day.day,
            date: Timestamp.fromDate(new Date(day.date)),
            value: depositAmountValues[dayIndex] || 0,
          })
        ),
        total: calculateTotalDepositAmount(),
      }

      // Surcharge Data
      const surchargeData = {
        values: selectedEntryData?.weekDays?.map(
          (day: any, dayIndex: number) => ({
            day: day.day,
            date: Timestamp.fromDate(new Date(day.date)),
            value: creditCardSurchargeValues[dayIndex] || 0,
          })
        ),
        total: calculateTotalCreditCardSurcharge(),
      }

      // Blue Bar Data
      const blueBarData = {
        values: selectedEntryData?.weekDays?.map(
          (day: any, dayIndex: number) => ({
            day: day.day,
            date: Timestamp.fromDate(new Date(day.date)),
            value: blueBarValues[dayIndex] || 0,
          })
        ),
        total: calculateTotalBlueBar() || 0,
      }
      // Calculated Cash Turn-In Data
      const cashTurnInData = {
        values: selectedEntryData?.weekDays?.map(
          (day: any, dayIndex: number) => ({
            day: day.day,
            date: Timestamp.fromDate(new Date(day.date)),
            value: calculateCashTurnInForDay(dayIndex) || 0,
          })
        ),
        total: calculateTotalCashTurnIn(),
      }
      // Revenue Data per Day
      const revenueDataPerDay = selectedEntryData?.weekDays?.map(
        (day: any, dayIndex: number) => ({
          day: day.day, // Day (e.g., "Tuesday")
          date: Timestamp.fromDate(new Date(day.date)), // Convert date to Firebase Timestamp
          totalRevenue: calculateTotalRevenuePerDay(dayIndex) || 0, // Total revenue for the day
        })
      )

      // Overall Revenue Data
      const revenueData = {
        values: revenueDataPerDay, // Revenue data for each day with date
        total: totalRevenueSum.toFixed(2), // Total revenue for the week
      }

      // Gross Revenue Data per Day
      const grossRevenuePerDay = selectedEntryData?.weekDays?.map(
        (day: any, dayIndex: number) => ({
          day: day.day, // Day (e.g., "Tuesday")
          date: Timestamp.fromDate(new Date(day.date)), // Date (e.g., "10/10/2024")
          grossRevenue: calculateGrossRevenuePerDay(dayIndex) || 0, // Gross revenue for the day
        })
      )

      // Overall Gross Revenue Data
      const grossRevenueData = {
        values: grossRevenuePerDay, // Gross revenue data for each day with date
        total: totalGrossSum.toFixed(2), // Total gross revenue for the week
      }

      // Cars Data per Day (with day and date)
      const carsDataPerDay = selectedEntryData?.weekDays?.map(
        (day: any, dayIndex: number) => ({
          day: day.day, // Day (e.g., "Tuesday")
          date: Timestamp.fromDate(new Date(day.date)), // Date (e.g., "10/10/2024")
          totalCars: calculateTotalCarsPerDay(dayIndex) || 0, // Total cars for the day
        })
      )

      // Overall Cars Data
      const carsData = {
        carsPerDay: carsDataPerDay, // Cars data for each day with date
        totalCarsForWeek: totalCarSum || 0, // Total cars for the week
        carCounts: carCounts,
      }

      let sDate = Timestamp.fromDate(
        new Date(selectedEntryData?.startDate || '')
      )

      let eDate = Timestamp.fromDate(new Date(selectedEntryData?.endDate || ''))

      const entryDailyStatistics =
        selectedEntryData?.weekDays?.map((day, dayIndex) => {
          // Calculate the daily total of conditional tips
          const dailyConditionalTips = conditionalTips.reduce(
            (sum, tip) => sum + (tip.values?.[dayIndex]?.value || 0),
            0
          )

          // Calculate the daily total of received tips (both direct and conditional)
          const dailyReceivedTips =
            Number(tipValues?.[dayIndex] || 0) + dailyConditionalTips
          if (dayIndex === 0) {
            sDate = Timestamp.fromDate(new Date(day.date))
          }
          if (dayIndex === 6) {
            eDate = Timestamp.fromDate(new Date(day.date))
          }
          return {
            date: Timestamp.fromDate(new Date(day.date)), // Convert to Firebase Timestamp
            day: day.day, // Day name (e.g., "Monday", "Tuesday", etc.)
            totalCars: calculateTotalCarsPerDay(dayIndex) || 0,
            totalRevenue: calculateTotalRevenuePerDay(dayIndex) || 0,
            totalGrossRevenue: calculateGrossRevenuePerDay(dayIndex) || 0,
            totalValidationCars: validationCounts.reduce(
              (sum, validation) =>
                sum + (validation.values?.[dayIndex]?.value || 0),
              0
            ),
            totalValidationRevenue: validationCounts.reduce(
              (sum, validation) =>
                sum +
                ((validation.values?.[dayIndex]?.value || 0) *
                  validation.validationRate || 0),
              0
            ),
            totalCreditCard: creditCardValues?.[dayIndex] || 0,
            totalReceivedTips: dailyReceivedTips, // Total of all received tips for the day
            totalTips: dailyReceivedTips, // Setting totalTips to include all received tips
            totalConditionalTips: dailyConditionalTips, // Total conditional tips for the day
            totalCcTipsPaid: ccTipsPaidValues?.[dayIndex] || 0,
            totalCreditCardSurcharge:
              creditCardSurchargeValues?.[dayIndex] || 0,
            totalBlueBar: blueBarValues?.[dayIndex] || 0,
            totalCalculatedCashTurnIn: calculateCashTurnInForDay(dayIndex) || 0,
            totalDepositAmount: depositAmountValues?.[dayIndex] || 0,
          }
        }) || []

      // console.log("entryDailyStatistics:", entryDailyStatistics);
      // return;

      const entryCalculations = {
        blueBar: blueBarData,
        ccTipsPaid: ccTipsPaidData,
        cashTurnIn: cashTurnInData,
        creditCard: creditCardData,
        deposit: depositData,
        revenue: revenueData,
        grossRevenue: grossRevenueData,
        surcharge: surchargeData,
        tipsReceived: tipData[0],
        totalTipsReceived: totalTipsReceived,
      }
      // return;

      const entryData = {
        startDate: sDate,
        endDate: eDate,
        locationId: selectedEntryData?.location?.id,
        totalCars: totalCarSum,
        totalRevenue: totalRevenueSum || 0,
        totalGross: totalGrossSum || 0,
        totalblubar: calculateTotalBlueBar() || 0,
        totalCreditCardSurcharge: calculateTotalCreditCardSurcharge() || 0,
        totalDepositAmount: calculateTotalDepositAmount() || 0,
        totalCreditCard: calculateTotalCreditCards() || 0,
        totalCcTipsPaid: calculateTotalCcTipsPaid() || 0,
        totalTips: tipData[0]?.totalRevenue || 0,
        totalTipsRevenue: calculateGrandTotalTips() || 0,
        totalValidationRevenue:
          validationSums.reduce((sum, val) => sum + val, 0) || 0,
        totalValidationCars:
          Object.values(validationCarCounts).reduce(
            (sum, val) => sum + val.reduce((sum2, val2) => sum2 + val2, 0),
            0
          ) || 0,
        totalcalculatedCashTurnIn: calculateTotalCashTurnIn() || 0,
        totalConditinalTips: totalConditinalTips || 0,
        stand: selectedEntryData?.stand,
        status: selectedEntryData?.status || 'open',
        entryDailyStatistics,
        rates: selectedEntryData?.location?.rates,
        conditionalRates: selectedEntryData?.location?.conditionalRates,
        validations: selectedEntryData?.location?.validations,
        conditionalVip: selectedEntryData?.location?.conditionalVip,
        blueBar: selectedEntryData?.location?.blueBar,
      }
      // console.log("selectedentry:", selectedEntryData);
      // console.log("entryData:", entryData);
      // return;

      // Call the EntryService to save all the data
      await EntryService.createEntry(
        entryData,
        entryCalculations,
        vipCounts,
        validationCounts,
        conditionalTips,
        carsData,
        selectedEntryData?.entryId
      )
      setLoading(false)
      setAlert({
        show: true,
        message: 'Entry saved successfully!',
        variant: 'success',
      })
      navigate(ROUTES.ENTRIES) // Redirect to the Entries page
    } catch (error) {
      setLoading(false)
      setAlert({
        show: true,
        message: 'Error saving data',
        variant: 'error',
      })
      console.error('Error saving data:', error)
    }
  }

  return (
    <Box
      px={{ xs: '0px', md: 4 }}
      sx={{ overflow: 'auto', mb: { xs: 0, md: 6 }, zIndex: 10 }}
    >
      <Box position={'relative'} marginBottom={5}>
        <CBox
          jsb
          as
          wrp
          px={4}
          pt={{ xs: 3, md: 0 }}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          <Box></Box>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              display: 'flex',
              height: '40px',
              alignItems: 'center',
              justifyContent: { xs: 'start', md: 'center' },
              backgroundColor: loading ? '#999' : '#0A9959',
              color: '#fff',
            }}
          >
            {loading ? (
              <>
                <CircularProgress
                  size={20}
                  sx={{ color: '#fff', marginRight: '10px' }}
                />
                Saving Entry...
              </>
            ) : (
              <>
                <Box
                  component='img'
                  src='/assetsOaks/icon/save.svg'
                  alt='export'
                  sx={{
                    width: '19px',
                    height: '19px',
                    objectFit: 'contain',
                    marginRight: '7px',
                  }}
                />
                Save
              </>
            )}
          </Button>
        </CBox>
        <Box
          borderTop='2px solid #E0E0E0'
          sx={{
            width: '100%',
            mt: 3,
          }}
        ></Box>
      </Box>
      {loadingEditData ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <CircularProgress />
        </Box>
      ) : (
        ''
      )}
      <TableContainer>
        <Table aria-label='weekly report table'>
          <TableHead>
            <TableRow>
              <TableCell
                align='center'
                sx={{ border: 'none', fontWeight: 'bold' }}
              ></TableCell>
              {selectedEntryData?.weekDays?.map((day) => (
                <TableCell
                  key={day.day}
                  align='center'
                  sx={{ backgroundColor: '#EAEAEA', border: 'none' }}
                >
                  <Box textAlign='center'>
                    <Typography variant='subtitle1' sx={styleSheet.weekHeader}>
                      {day.day}
                    </Typography>
                    <Typography sx={styleSheet.weekHeader}>
                      {day.date}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.contentText}>
                Weekly Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={12} sx={{ borderBottom: 'none' }}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography sx={styleSheet.contentText}>
                    Cars Counts
                  </Typography>
                  <Typography sx={styleSheet.contentText}>
                    Cars Revenue
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            {selectedEntryData?.location?.rates?.map(
              (rate: number, rowIndex: number) => (
                <TableRow key={rowIndex}>
                  <TableCell
                    align='right'
                    sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
                  >
                    ${rate}
                  </TableCell>
                  {daysOfWeek.map((_, dayIndex: number) => (
                    <TableCell key={dayIndex}>
                      <TextField
                        variant='outlined'
                        size='small'
                        sx={{
                          width: '100px',
                          '& .MuiInputBase-input': {
                            padding: '8px',
                            border: '2px solid #000000',
                            borderRadius: '7px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: 'rgba(0, 0, 0, 0.6)',
                          },
                        }}
                        value={inputValues[rate]?.[dayIndex] || 0}
                        onChange={(e) =>
                          handleInputChange(
                            rate.toString(),
                            dayIndex,
                            Number(e.target.value)
                          )
                        }
                      />
                    </TableCell>
                  ))}
                  <TableCell align='center' sx={styleSheet.grossCount}>
                    {carCountValues[rate] || 0} $
                    {revenueValues[rate]?.toFixed(2)}
                  </TableCell>
                </TableRow>
              )
            )}
            {selectedEntryData?.location?.conditionalVip === 'Yes' ? (
              <TableRow>
                <TableCell colSpan={12} sx={{ borderBottom: 'none' }}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography sx={styleSheet.contentText}>
                      Conditional VIP
                    </Typography>
                    <Typography sx={styleSheet.contentText}>
                      Cars Revenue
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}

            {selectedEntryData?.location?.conditionalVip === 'Yes' &&
              selectedEntryData?.location?.conditionalRates.map(
                (conditionalRate: any, rowIndex: number) => (
                  <TableRow key={rowIndex}>
                    <TableCell
                      align='right'
                      sx={{
                        fontWeight: 'bold',
                        padding: '16px',
                        border: 'none',
                      }}
                    >
                      ${conditionalRate.rate1}
                    </TableCell>
                    {daysOfWeek.map((_, dayIndex: number) => (
                      <TableCell key={dayIndex}>
                        <TextField
                          variant='outlined'
                          size='small'
                          sx={{
                            width: '100px',
                            '& .MuiInputBase-input': {
                              padding: '8px',
                              border: '2px solid #000000',
                              borderRadius: '7px',
                            },
                            '& .MuiInputBase-input::placeholder': {
                              color: 'rgba(0, 0, 0, 0.6)',
                            },
                          }}
                          value={
                            inputValues[conditionalRate.rate1]?.[dayIndex] || 0
                          }
                          onChange={(e) =>
                            handleInputChange(
                              conditionalRate.rate1.toString(),
                              dayIndex,
                              Number(e.target.value)
                            )
                          }
                        />
                      </TableCell>
                    ))}
                    <TableCell align='center' sx={styleSheet.grossCount}>
                      {carCountValues[conditionalRate.rate1] || 0} $
                      {revenueValues[conditionalRate.rate1]?.toFixed(2) ||
                        '0.00'}
                    </TableCell>
                  </TableRow>
                )
              )}

            {/* Totals for Cars */}
            <TableRow>
              <TableCell
                align='right'
                sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
              >
                Cars
              </TableCell>
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell
                  key={dayIndex}
                  align='center'
                  sx={{ backgroundColor: '#EAEAEA', border: 'none' }}
                >
                  {calculateTotalCarsPerDay(dayIndex) || '-'}
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                {totalCarSum || '-'} Cars
              </TableCell>
            </TableRow>

            {/* Totals for Revenue */}
            <TableRow>
              <TableCell
                align='right'
                sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
              >
                Revenue
              </TableCell>
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell
                  key={dayIndex}
                  align='center'
                  sx={{ backgroundColor: '#EAEAEA', border: 'none' }}
                >
                  ${calculateTotalRevenuePerDay(dayIndex).toFixed(2) || '-'}
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                ${totalRevenueSum.toFixed(2)}
              </TableCell>
            </TableRow>

            {/* Validation Inputs */}
            {selectedEntryData?.location?.validations &&
            selectedEntryData?.location?.validations?.length > 0 ? (
              <TableRow>
                <TableCell colSpan={12} sx={{ borderBottom: 'none' }}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography sx={styleSheet.contentText}>
                      Validations
                    </Typography>
                    <Typography sx={styleSheet.contentText}>
                      Cars Revenue
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {selectedEntryData?.location?.validations.map((row: any) => (
              <TableRow key={row.name + row.validation}>
                <TableCell
                  align='right'
                  sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
                >
                  {row.name} -${row.validation}
                </TableCell>
                {daysOfWeek.map((_, dayIndex: number) => (
                  <TableCell key={dayIndex}>
                    <TextField
                      variant='outlined'
                      size='small'
                      sx={{
                        width: '100px',
                        '& .MuiInputBase-input': {
                          padding: '8px',
                          border: '2px solid #000000',
                          borderRadius: '7px',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          color: 'rgba(0, 0, 0, 0.6)',
                        },
                      }}
                      // Ensure the correct value is displayed from validationValues
                      value={
                        validationValues[row.name + row.validation]?.[
                          dayIndex
                        ] || 0
                      }
                      onChange={(e) =>
                        handleValidationInputChange(
                          row.name, // Pass the name
                          row.validation, // Pass the validation rate
                          dayIndex, // Pass the dayIndex
                          Number(e.target.value) // Pass the new value
                        )
                      }
                    />
                  </TableCell>
                ))}
                <TableCell align='center' sx={styleSheet.grossCount}>
                  {calculateValidationRowCars(row.name, row.validation)} -$
                  {calculateValidationRowRevenue(row.name, row.validation)}
                </TableCell>
              </TableRow>
            ))}

            {/* Sum of Validations per Day */}
            {selectedEntryData?.location?.validations &&
            selectedEntryData?.location?.validations?.length > 0 ? (
              <TableRow>
                <TableCell
                  align='right'
                  sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
                >
                  Validations
                </TableCell>
                {daysOfWeek.map((_, dayIndex: number) => (
                  <TableCell
                    key={dayIndex}
                    align='center'
                    sx={{ backgroundColor: '#EAEAEA', border: 'none' }}
                  >
                    -${validationSums[dayIndex]?.toFixed(2) || '-'}
                  </TableCell>
                ))}
                <TableCell align='center' sx={styleSheet.grossCount}>
                  -${calculateValidationGrandTotal()}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}

            {/* Credit Card */}

            {/* Gross Revenue */}
            <TableRow>
              <TableCell
                align='right'
                sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
              >
                Gross Revenue
              </TableCell>
              {daysOfWeek.map((_, dayIndex: number) => (
                <TableCell
                  key={dayIndex}
                  align='center'
                  sx={{ backgroundColor: '#EAEAEA', border: 'none' }}
                >
                  ${calculateGrossRevenuePerDay(dayIndex).toFixed(2)}
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                ${totalGrossSum.toFixed(2)}
              </TableCell>
            </TableRow>
            {selectedEntryData?.location?.conditionalVip === 'Yes' ? (
              <TableRow>
                <TableCell colSpan={12} sx={{ borderBottom: 'none' }}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography sx={styleSheet.contentText}>
                      Conditional TIP
                    </Typography>
                    <Typography sx={styleSheet.contentText}>Amount</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}

            {selectedEntryData?.location?.conditionalVip === 'Yes' &&
              selectedEntryData?.location?.conditionalRates.map(
                (conditionalRate: any, rowIndex: number) => {
                  // Initialize a variable to accumulate the total sum for this row
                  let totalSum = 0

                  return (
                    <TableRow key={rowIndex}>
                      <TableCell
                        align='right'
                        sx={{
                          fontWeight: 'bold',
                          padding: '16px',
                          border: 'none',
                        }}
                      >
                        ${conditionalRate.rate2}
                      </TableCell>
                      {daysOfWeek.map((_, dayIndex) => {
                        // Calculate the value for each day by multiplying with rate2
                        const dayValue =
                          (inputValues[conditionalRate.rate1]?.[dayIndex] ||
                            0) * Number(conditionalRate.rate2)

                        // Accumulate the total sum for this row
                        totalSum += dayValue

                        return (
                          <TableCell
                            key={dayIndex}
                            sx={{ backgroundColor: '#EAEAEA', border: 'none' }}
                          >
                            ${dayValue.toFixed(2)}
                          </TableCell>
                        )
                      })}

                      {/* Display the total sum of the row in the gross count cell */}
                      <TableCell align='center' sx={styleSheet.grossCount}>
                        ${totalSum.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}

            <TableRow>
              <TableCell
                align='right'
                sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
              >
                Tips Received
              </TableCell>

              {/* Input Fields for each day */}
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell key={dayIndex}>
                  <TextField
                    variant='outlined'
                    size='small'
                    sx={{
                      width: '100px',
                      '& .MuiInputBase-input': {
                        padding: '8px',
                        border: '2px solid #000000',
                        borderRadius: '7px',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                    value={tipValues[dayIndex] || ''} // No need for rowIndex now
                    onChange={(e) =>
                      handleTipsInputChange(dayIndex, e.target.value)
                    }
                  />
                </TableCell>
              ))}

              {/* Total Tips for the Row */}
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                ${calculateTotalTipsRevenueForRow().toFixed(2)}
              </TableCell>
            </TableRow>

            {/* Total Tips Received */}
            <TableRow>
              <TableCell
                align='right'
                sx={{ fontWeight: 'bold', padding: '16px', border: 'none' }}
              >
                Total Tips Received
              </TableCell>
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell
                  key={dayIndex}
                  sx={{ backgroundColor: '#EAEAEA', border: 'none' }}
                >
                  ${calculateTotalTipsForDay(dayIndex).toFixed(2)}
                </TableCell>
              ))}

              {/* Grand Total */}
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                ${calculateGrandTotalTips().toFixed(2)}
              </TableCell>
            </TableRow>

            <Box sx={{ mt: 3 }}></Box>
            {/* /// CC Tips Paid on Paycheck /// */}

            <TableRow>
              <TableCell style={{ border: 'none' }}>
                <Typography
                  align='right'
                  sx={{
                    fontWeight: 'bold',
                    padding: '16px',
                    border: 'none',
                  }}
                >
                  CC Tips Paid on Paycheck
                </Typography>
              </TableCell>

              {/* Input Fields for each day */}
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell key={dayIndex}>
                  <TextField
                    variant='outlined'
                    size='small'
                    placeholder='$'
                    value={ccTipsPaidValues[dayIndex] || ''} // Show the value in the field
                    onChange={(e) =>
                      handleCcTipsPaidInputChange(dayIndex, e.target.value)
                    }
                    sx={{
                      width: '100px',
                      '& .MuiInputBase-input': {
                        padding: '8px',
                        border: '2px solid #000000',
                        borderRadius: '7px',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                  />
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                ${calculateTotalCcTipsPaid().toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: 'none' }}>
                <Typography
                  align='right'
                  sx={{
                    fontWeight: 'bold',
                    padding: '16px',
                    border: 'none',
                  }}
                >
                  Total Credit Cards
                </Typography>
              </TableCell>

              {/* Input Fields for each day */}
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell key={dayIndex}>
                  <TextField
                    variant='outlined'
                    size='small'
                    placeholder='$'
                    value={creditCardValues[dayIndex] || ''} // Show the value in the field
                    onChange={(e) =>
                      handleCreditCardInputChange(dayIndex, e.target.value)
                    }
                    sx={{
                      width: '100px',
                      '& .MuiInputBase-input': {
                        padding: '8px',
                        border: '2px solid #000000',
                        borderRadius: '7px',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                  />
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                ${calculateTotalCreditCards().toFixed(2)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ border: 'none' }}>
                <Typography
                  align='right'
                  sx={{
                    fontWeight: 'bold',
                    padding: '16px',
                    border: 'none',
                  }}
                >
                  Credit Card Surcharge
                </Typography>
              </TableCell>

              {/* Input Fields for each day */}
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell key={dayIndex}>
                  <TextField
                    variant='outlined'
                    size='small'
                    placeholder='$'
                    value={creditCardSurchargeValues[dayIndex] || ''}
                    onChange={(e) =>
                      handleCreditCardSurchargeChange(dayIndex, e.target.value)
                    }
                    sx={{
                      width: '100px',
                      '& .MuiInputBase-input': {
                        padding: '8px',
                        border: '2px solid #000000',
                        borderRadius: '7px',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                  />
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                ${calculateTotalCreditCardSurcharge().toFixed(2)}
              </TableCell>
            </TableRow>
            {selectedEntryData?.location?.blueBar === 'Yes' ? (
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Typography
                    align='right'
                    sx={{
                      fontWeight: 'bold',
                      padding: '16px',
                      border: 'none',
                    }}
                  >
                    Blue Bar
                  </Typography>
                </TableCell>

                {/* Input Fields for each day */}
                {daysOfWeek.map((_, dayIndex) => (
                  <TableCell key={dayIndex}>
                    <TextField
                      variant='outlined'
                      size='small'
                      placeholder='$'
                      value={blueBarValues[dayIndex] || ''}
                      onChange={(e) =>
                        handleBlueBarChange(dayIndex, e.target.value)
                      }
                      sx={{
                        width: '100px',
                        '& .MuiInputBase-input': {
                          padding: '8px',
                          border: '2px solid #000000',
                          borderRadius: '7px',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          color: 'rgba(0, 0, 0, 0.6)',
                        },
                      }}
                    />
                  </TableCell>
                ))}
                <TableCell align='center' sx={styleSheet.grossCount}>
                  ${calculateTotalBlueBar().toFixed(2)}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}

            <TableRow>
              <TableCell style={{ border: 'none' }}>
                <Typography
                  align='right'
                  sx={{
                    fontWeight: 'bold',
                    padding: '16px',
                    border: 'none',
                  }}
                >
                  Calculated Cash Turn-In
                </Typography>
              </TableCell>

              {/* Cash Turn-In for each day */}
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell key={dayIndex}>
                  ${calculateCashTurnInForDay(dayIndex).toFixed(2)}
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                ${calculateTotalCashTurnIn().toFixed(2)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ border: 'none' }}>
                <Typography
                  align='right'
                  sx={{
                    fontWeight: 'bold',
                    padding: '16px',
                    border: 'none',
                  }}
                >
                  Deposit Amount
                </Typography>
              </TableCell>

              {/* Input Fields for each day */}
              {daysOfWeek.map((_, dayIndex) => (
                <TableCell key={dayIndex}>
                  <TextField
                    variant='outlined'
                    size='small'
                    placeholder='$'
                    value={depositAmountValues[dayIndex] || ''} // Show the value in the field
                    onChange={(e) =>
                      handleDepositAmountInputChange(dayIndex, e.target.value)
                    }
                    sx={{
                      width: '100px',
                      '& .MuiInputBase-input': {
                        padding: '8px',
                        border: '2px solid #000000',
                        borderRadius: '7px',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                  />
                </TableCell>
              ))}
              <TableCell align='center' sx={styleSheet.grossCount}>
                ${calculateTotalDepositAmount().toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default CreateEntry

const styleSheet = {
  grossCount: {
    fontFamily: 'Interstate',
    fontSize: '18px',
    color: '#000000',
    fontWeight: 400,
    lineHeight: '21.6px',
    letterSpacing: '3%',
    border: 'none',
  },
  weekHeader: {
    fontSize: '16px',
    color: '#000000',
    fontWeight: '600',
    lineHeight: '21.6px',
    letterSpacing: '3%',
    border: 'none',
  },
  contentText: {
    border: 'none',
    fontWeight: 700,
    fontSize: '16px',
    color: '#000000',
  },
}
