// @ts-nocheck
import React, { forwardRef, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { DateRangePicker } from 'react-date-range'
import { enUS } from 'date-fns/locale'
import './../../styles/calendar.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

interface CustomDatePickerProps {
  selectedDate: {
    startDate: Date
    endDate: Date
    key: string
  }[]
  onChange: (ranges: {
    selection: { startDate: Date; endDate: Date; key: string }
  }) => void
  showDatePicker: boolean
  onClose: () => void
}

const CustomDatePicker = forwardRef<HTMLDivElement, CustomDatePickerProps>(
  ({ selectedDate, onChange, showDatePicker, onClose }) => {
    const pickerRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        onClose() // Call onClose if clicked outside
      }
    }

    useEffect(() => {
      if (showDatePicker) {
        document.addEventListener('mousedown', handleClickOutside)
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [showDatePicker])

    const handleDateChange = (ranges: any) => {
      onChange(ranges)
    }

    if (!showDatePicker) return null

    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        zIndex={100}
      >
        <div ref={pickerRef}>
          <DateRangePicker
            ranges={selectedDate}
            locale={enUS}
            onChange={handleDateChange}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            rangeColors={['#0A9959']}
            className='customDatePicker'
          />
        </div>
      </Box>
    )
  }
)

export default CustomDatePicker
