import { firestore } from 'libs/firebase/@firebase'
import { COLLECTIONS } from 'constant'
import { Location, Admin, GroupedLocation } from 'models/schema'
import { getDocumentById, subscribeToCollection } from 'utils/firebaseUtils'
import {
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  collection,
  FirestoreError,
  where,
  getDoc,
  onSnapshot,
} from 'firebase/firestore'
import { getDocs, query, orderBy } from 'firebase/firestore'

class Locations {
  getAllLocations2 = (
    locationIds: string[] | null,
    role: string,
    dataCallback: (locations: Location[]) => void,
    errorCallback: (error: FirestoreError) => void
  ): (() => void) => {
    const locationCollectionRef = collection(firestore, COLLECTIONS.LOCATIONS)

    if (role === 'Admin') {
      // Admin: Listen to all locations in real-time
      const allLocationsQuery = query(locationCollectionRef)

      const unsubscribe = onSnapshot(
        allLocationsQuery,
        (querySnapshot) => {
          const locations: Location[] = []
          querySnapshot.forEach((doc) => {
            const locationData = doc.data() as Location
            locationData.id = doc.id
            locations.push(locationData)
          })

          // Sort locations by locationRc
          locations.sort(
            (a, b) => parseInt(a.locationRc, 10) - parseInt(b.locationRc, 10)
          )

          dataCallback(locations) // Call the callback with updated data
        },
        (error) => errorCallback(error) // Handle errors
      )

      return unsubscribe
    } else if (locationIds && locationIds.length > 0) {
      // Non-admin user: Set up real-time listeners for each specified location ID
      const locations: Location[] = []
      const unsubscribeFunctions = locationIds.map((locationId) => {
        const locationDocRef = doc(firestore, COLLECTIONS.LOCATIONS, locationId)

        return onSnapshot(
          locationDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const locationData = docSnapshot.data() as Location
              locationData.id = docSnapshot.id

              // Update or add the location in the array
              const existingIndex = locations.findIndex(
                (loc) => loc.id === locationData.id
              )
              if (existingIndex !== -1) {
                locations[existingIndex] = locationData
              } else {
                locations.push(locationData)
              }

              // Sort locations by locationRc after updating
              locations.sort(
                (a, b) =>
                  parseInt(a.locationRc, 10) - parseInt(b.locationRc, 10)
              )

              // Trigger callback with updated locations
              dataCallback([...locations])
            }
          },
          (error) => errorCallback(error)
        )
      })

      // Return a function to unsubscribe from all document listeners
      return () => unsubscribeFunctions.forEach((unsubscribe) => unsubscribe())
    } else {
      return () => {} // No-op if no locations or role defined
    }
  }

  getGroupedLocations = (
    locationIds: string[] | null,
    role: string,
    dataCallback: (locations: GroupedLocation[]) => void,
    errorCallback: (error: FirestoreError) => void
  ): (() => void) => {
    const locationCollectionRef = collection(firestore, COLLECTIONS.LOCATIONS)

    const fetchLocations = async () => {
      try {
        const locationMap = new Map<string, GroupedLocation>()

        if (role === 'Admin') {
          // Fetch all locations if the user is an Admin
          const allLocationsQuery = query(locationCollectionRef)
          const querySnapshot = await getDocs(allLocationsQuery)

          querySnapshot.forEach((doc) => {
            const locationData = doc.data() as Location
            locationData.id = doc.id

            // Filter only checked rates, conditional rates, and validations
            const filteredLocationData = {
              ...locationData,
              // rates: locationData.rates?.filter((rate: any) => rate.checked),
              conditionalRates: locationData.conditionalRates?.filter(
                (rate: any) => rate.checked
              ),
              validations: locationData.validations?.filter(
                (validation: any) => validation.checked
              ),
            }

            const { locationRc, locationName, standNumber } =
              filteredLocationData

            if (locationMap.has(locationName)) {
              // If locationName already exists, add the stand information
              locationMap.get(locationName)!.stands.push({
                standNumber,
                standName: filteredLocationData.standName,
                id: doc.id,
                location: filteredLocationData, // Include the filtered location object
              })
            } else {
              // If locationName does not exist, create a new entry
              locationMap.set(locationName, {
                locationRc,
                locationName,
                stands: [
                  {
                    standNumber,
                    standName: filteredLocationData.standName,
                    id: doc.id,
                    location: filteredLocationData, // Include the filtered location object
                  },
                ],
              })
            }
          })
        } else if (locationIds && locationIds.length > 0) {
          // Fetch each location by its document ID
          for (const locationId of locationIds) {
            console.log('Fetching location with ID:', locationId)
            const locationDocRef = doc(
              firestore,
              COLLECTIONS.LOCATIONS,
              locationId
            )
            const locationDoc = await getDoc(locationDocRef)

            if (locationDoc.exists()) {
              const locationData = locationDoc.data() as Location
              locationData.id = locationDoc.id

              // Filter only checked rates, conditional rates, and validations
              const filteredLocationData = {
                ...locationData,
                // rates: locationData.rates?.filter((rate: any) => rate.checked),
                conditionalRates: locationData.conditionalRates?.filter(
                  (rate: any) => rate.checked
                ),
                validations: locationData.validations?.filter(
                  (validation: any) => validation.checked
                ),
              }

              const { locationRc, locationName, standNumber } =
                filteredLocationData

              if (locationMap.has(locationName)) {
                locationMap.get(locationName)!.stands.push({
                  standNumber,
                  standName: filteredLocationData.standName,
                  id: locationDoc.id,
                  location: filteredLocationData, // Include the filtered location object
                })
              } else {
                locationMap.set(locationName, {
                  locationRc,
                  locationName,
                  stands: [
                    {
                      standNumber,
                      standName: filteredLocationData.standName,
                      id: locationDoc.id,
                      location: filteredLocationData, // Include the filtered location object
                    },
                  ],
                })
              }
            } else {
              console.warn(`Location with ID ${locationId} does not exist`)
            }
          }
        }

        // Sort stands within each location
        locationMap.forEach((groupedLocation) => {
          groupedLocation.stands.sort((a, b) => {
            const standA = parseInt(a.standNumber, 10)
            const standB = parseInt(b.standNumber, 10)
            return standA - standB
          })
        })

        // Convert map values to an array and sort locations by locationRc
        const locations = Array.from(locationMap.values()).sort((a, b) => {
          const locationRcA = parseInt(a.locationRc, 10)
          const locationRcB = parseInt(b.locationRc, 10)

          if (locationRcA < locationRcB) return -1
          if (locationRcA > locationRcB) return 1
          return 0
        })

        // console.log("Fetched locations:", locations);

        // Pass the grouped and sorted locations to the callback
        dataCallback(locations)
      } catch (error) {
        errorCallback(error as FirestoreError)
      }
    }

    // Fetch the locations
    fetchLocations()

    // Return a dummy unsubscribe function since this is a one-time fetch
    return () => {}
  }

  getAllLocations = (
    dataCallback: (locations: Location[]) => void,
    errorCallback: (error: FirestoreError) => void
  ): (() => void) => {
    const locationCollectionRef = collection(firestore, COLLECTIONS.LOCATIONS)

    // Set up Firestore real-time subscription using onSnapshot
    const unsubscribe = onSnapshot(
      locationCollectionRef,
      async (snapshot) => {
        const locations: Location[] = []

        // Loop over each document in snapshot and fetch related user locations
        for (const locationDoc of snapshot.docs) {
          const locationData = locationDoc.data() as Location
          locationData.id = locationDoc.id

          // Fetch user locations from userLocations collection
          const userLocationQuery = query(
            collection(firestore, COLLECTIONS.USERLOCATIONS),
            where('locationId', '==', locationDoc.id)
          )
          const userLocationSnapshot = await getDocs(userLocationQuery)

          const userIds = userLocationSnapshot.docs.map(
            (doc) => doc.data().userId
          )

          // Fetch user details for each userId
          const users: Admin[] = []
          for (const userId of userIds) {
            const userDoc = await getDoc(
              doc(firestore, COLLECTIONS.USERS, userId)
            )
            if (userDoc.exists()) {
              users.push({ id: userId, ...userDoc.data() } as Admin)
            }
          }

          locationData.users = users // Attach users to location
          locations.push(locationData)
        }

        dataCallback(locations) // Invoke the callback with the fetched data
      },
      (error) => {
        errorCallback(error) // Handle errors
      }
    )

    return unsubscribe
  }

  fetchLocationUsers = async (locationId: string): Promise<Admin[]> => {
    const userLocationQuery = query(
      collection(firestore, COLLECTIONS.USERLOCATIONS),
      where('locationId', '==', locationId)
    )
    const userLocationSnapshot = await getDocs(userLocationQuery)

    const userIds = userLocationSnapshot.docs.map((doc) => doc.data().userId)

    const users: Admin[] = []
    for (const userId of userIds) {
      const userDoc = await getDoc(doc(firestore, COLLECTIONS.USERS, userId))
      if (userDoc.exists()) {
        users.push({ id: userId, ...userDoc.data() } as Admin)
      }
    }

    return users
  }
  fetchUserLocations = async (userId: string): Promise<Location[]> => {
    const userLocationQuery = query(
      collection(firestore, COLLECTIONS.USERLOCATIONS),
      where('userId', '==', userId)
    )
    const userLocationSnapshot = await getDocs(userLocationQuery)

    const locationIds = userLocationSnapshot.docs.map(
      (doc) => doc.data().locationId
    )

    const locations: Location[] = []
    for (const locationId of locationIds) {
      const locationDoc = await getDoc(
        doc(firestore, COLLECTIONS.LOCATIONS, locationId)
      )
      if (locationDoc.exists()) {
        locations.push({ id: locationId, ...locationDoc.data() } as Location)
      }
    }

    return locations
  }

  associateLocationWithUsers = async (
    locationId: string,
    userIds: string[]
  ): Promise<void> => {
    const userLocationCollectionRef = collection(
      firestore,
      COLLECTIONS.USERLOCATIONS
    )

    try {
      // Fetch current associations from userLocations
      const existingAssociationsQuery = query(
        userLocationCollectionRef,
        where('locationId', '==', locationId)
      )
      const existingAssociationsSnapshot = await getDocs(
        existingAssociationsQuery
      )

      const existingUserIds: string[] = existingAssociationsSnapshot.docs.map(
        (doc) => doc.data().userId
      )

      // Determine which users need to be added
      const usersToAdd = userIds.filter(
        (userId) => !existingUserIds.includes(userId)
      )

      // Determine which users need to be removed
      const usersToRemove = existingUserIds.filter(
        (userId) => !userIds.includes(userId)
      )

      // Add new associations
      for (const userId of usersToAdd) {
        await addDoc(userLocationCollectionRef, {
          locationId,
          userId,
        })
      }

      // Remove old associations
      for (const userId of usersToRemove) {
        const userLocationToDelete = existingAssociationsSnapshot.docs.find(
          (doc) => doc.data().userId === userId
        )

        if (userLocationToDelete) {
          await deleteDoc(
            doc(firestore, COLLECTIONS.USERLOCATIONS, userLocationToDelete.id)
          )
        }
      }

      console.log('Location associations updated successfully')
    } catch (error) {
      console.error('Error updating location associations:', error)
      throw new Error('Failed to update location associations')
    }
  }

  createLocation = async (data: any): Promise<void> => {
    try {
      const adminCollectionRef = collection(firestore, COLLECTIONS.LOCATIONS)

      // Extract users from data and remove it from the data object
      const { users, ...locationData } = data

      // Create the location without the users array
      const locationRef = await addDoc(adminCollectionRef, locationData)
      const locationId = locationRef.id

      // Associate users with the created location
      if (users && users.length > 0) {
        await this.associateLocationWithUsers(locationId, users)
      }

      console.log('Location created successfully')
    } catch (error) {
      console.error('Error creating location:', error)
      throw error
    }
  }

  // Method to update an existing admin
  updateLocation = async (id: string, data: any): Promise<void> => {
    try {
      const adminDocRef = doc(firestore, COLLECTIONS.LOCATIONS, id)

      // Extract users from data and remove it from the data object
      const { users, ...locationData } = data

      // Update the location without the users array
      await setDoc(adminDocRef, locationData, { merge: true })

      // Associate users with the updated location
      if (users && users.length > 0) {
        await this.associateLocationWithUsers(id, users)
      }

      console.log('Location updated successfully')
    } catch (error) {
      console.error('Error updating location:', error)
      throw error
    }
  }

  deleteLocation = async (id: string): Promise<void> => {
    try {
      const adminDocRef = doc(firestore, COLLECTIONS.LOCATIONS, id) // Assuming 'users' collection
      await deleteDoc(adminDocRef)
      console.log(`Location with ID ${id} has been deleted.`)
    } catch (error) {
      console.error('Error deleting admin:', error)
      throw error
    }
  }

  searchLocationByRc = async (locationRc: string): Promise<Location> => {
    try {
      const locationCollectionRef = collection(firestore, COLLECTIONS.LOCATIONS)

      // Query locations where location_Rc matches the input
      const locationQuery = query(
        locationCollectionRef,
        where('locationRc', '==', locationRc)
      )

      // Execute the query
      const querySnapshot = await getDocs(locationQuery)

      // Extract and process the results
      const locations: Location[] = querySnapshot.docs.map((doc) => {
        const locationData = doc.data() as Location
        locationData.id = doc.id
        return locationData // Return the first location found
      })

      return locations[0] ?? null // Return the fetched locations
    } catch (error) {
      console.error('Error searching for location by Rc:', error)
      throw error // Propagate the error for further handling
    }
  }
}

const LocationService = new Locations()

export { LocationService }
