import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  IconButton,
  Chip,
  Grid,
  InputAdornment,
  CircularProgress,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import TextFieldCustom from 'components/TextFieldCustom'
import { RenderField, RenderSelect } from 'components'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LocationService } from 'services/Location.Services'
import { ProfileService } from 'services/Profile.Services'
import { blue } from '@mui/material/colors'
import { useNotification } from '../../../../context/notificationContext'
import { useAuthContext } from 'context/AuthContext'
interface CreateLocationModalProps {
  open: boolean
  onClose: () => void
  mode: 'create' | 'edit' // Mode is either 'create' or 'edit'
  location?: any // Location data if editing
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  locationRc: Yup.string()
    .required('Location RC is required')
    .matches(/^\d{1,4}$/, 'RC # must be 1 to 4 digits'), // allows up to 4 digits only
  locationName: Yup.string().required('Location Name is required'),
  standNumber: Yup.string()
    .required('Stand # is required')
    .matches(/^\d{1,2}$/, 'Stand # must be 1 to 2 digits'), // allows up to 2 digits only
  standName: Yup.string().required('Stand Name is required'),
})

// const CreateLocationModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
const CreateLocationModal: React.FC<CreateLocationModalProps> = ({
  open,
  onClose,
  mode,
  location,
}) => {
  const [customRate, setCustomRate] = useState('')
  const { setAlert } = useNotification()

  const [ConditionRate, setConditionRate] = useState('')
  const [ConditionRateSecond, setConditionRateSecond] = useState('')
  const [userSearch, setUserSearch] = useState('')
  const [searchResults, setSearchResults] = useState<any[]>([]) // Store search results
  // const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  // const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const { user } = useAuthContext()
  const [selectedUsers, setSelectedUsers] = useState<
    { id: string; name: string; email: string }[]
  >([])
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]) // Store just the user IDs

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)

  interface Rate {
    checked: boolean
    rate: string
  }
  interface RateValidation {
    checked: boolean
    validation: string
    name: string
  }
  interface ConditionalRate {
    checked: boolean
    rate1: string
    rate2: string
  }

  const [rates, setRates] = useState<Rate[]>([])

  const [rateValidation, setRateValidation] = useState<RateValidation[]>([])
  const [conditionalRates, setConditionalRates] = useState<ConditionalRate[]>(
    []
  )

  const [validations, setValidations] = useState('')
  const [NameRate, setNameRate] = useState('')

  const handleRateChange = (index: number) => {
    const newRates = [...rates]
    newRates[index].checked = !newRates[index].checked

    setRates(newRates)
  }

  const handleAddCustomRate = () => {
    if (customRate.trim()) {
      const newRates = [...rates, { checked: true, rate: customRate }]

      // Sort the new array by rate in ascending order (assuming all rates are numeric)
      const sortedRates = newRates.sort(
        (a, b) => parseFloat(a.rate) - parseFloat(b.rate)
      )

      setRates(sortedRates)
      setCustomRate('')
    } else {
      alert('Please enter rate')
    }
  }
  const handleRateConditionChange = (index: number) => {
    const newRates = [...conditionalRates]
    newRates[index].checked = !newRates[index].checked
    setConditionalRates(newRates)
  }

  const handleAddConditionRate = () => {
    if (ConditionRate.trim() && ConditionRateSecond.trim()) {
      const newConditionalRates = [
        ...conditionalRates,
        { checked: true, rate1: ConditionRate, rate2: ConditionRateSecond },
      ]

      // Sort the conditional rates by rate1 (numeric)
      const sortedConditionalRates = newConditionalRates.sort(
        (a, b) => parseFloat(a.rate1) - parseFloat(b.rate1)
      )

      setConditionalRates(sortedConditionalRates)
      setConditionRate('')
      setConditionRateSecond('')
    } else {
      alert('Please enter conditional rate')
    }
  }

  // console.log("location", location);

  const handleRateValidationChange = (index: number) => {
    const newRates = [...rateValidation]
    newRates[index].checked = !newRates[index].checked
    setRateValidation(newRates)
  }

  const handleAddValidationRate = () => {
    if (validations.trim() && NameRate.trim()) {
      const newRateValidation = [
        ...rateValidation,
        { checked: true, validation: validations, name: NameRate },
      ]

      // Sort by validation number in ascending order
      const sortedRateValidation = newRateValidation.sort(
        (a, b) => parseFloat(a.validation) - parseFloat(b.validation)
      )

      setRateValidation(sortedRateValidation)
      setValidations('')
      setNameRate('')
    } else {
      alert('Please enter validations')
    }
  }

  // Populate fields when in edit mode
  useEffect(() => {
    if (mode === 'edit' && location) {
      console.log('Location in edit mode:', location)
      // For Rates
      //check if all rates is not empty
      console.log(location?.allRates?.length)
      if (!location?.allRates || location?.allRates?.length === 0) {
        const updatedRates = location?.rates?.map((rate: string) => ({
          rate: rate,
          checked: true, // All rates from location are marked as checked
        }))
        setRates(updatedRates)
      } else {
        const updatedRates2 = location?.allRates?.map((rate: any) => ({
          rate: rate?.rate,
          checked: rate.checked,
        }))
        setRates(updatedRates2)
        console.log('updatedRates2', updatedRates2)
      }

      // For Conditional Rates
      const updatedConditionalRates = location.conditionalRates.map(
        (locRate: any) => ({
          ...locRate,
          // checked: true, // All conditional rates from location are marked as checked
        })
      )

      setConditionalRates(updatedConditionalRates)

      // For Validations
      const updatedValidations = location.validations.map(
        (locValidation: any) => ({
          ...locValidation,
          // checked: true, // All validations from location are marked as checked
        })
      )

      setRateValidation(updatedValidations)

      // Set selected users with location.users array
      fetchLocationUsers(location.id)
      // const users=await LocationService.fetchLocationUsers(location.id);
      // console.log("users",users);

      // Optionally set location-specific fields like location name or rc if needed
      // setLocationName(location.locationName);
      // setLocationRC(location.locationRc);
    } else {
      // Reset or clear states when not in edit mode
      setRates([])
      setConditionalRates([])
      setRateValidation([])
      setSelectedUsers([])
      setSelectedUsersIds([])
      // Optionally reset location-specific fields
      // setLocationName("");
      // setLocationRC("");
    }
  }, [mode, location, open])

  async function fetchLocationUsers(locationId: string) {
    try {
      const users = await LocationService.fetchLocationUsers(locationId)
      // console.log("users", users);
      setSelectedUsers(users)
      if (users.length > 0) {
        setSelectedUsersIds(users.map((user: any) => user.id))
      }
    } catch (error) {
      console.error('Error fetching location users:', error)
    }
  }

  // Handle user search input change and trigger the search
  const handleUserSearchChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const query = e.target.value
    setUserSearch(query)

    if (query.trim().length > 2) {
      // Perform search only after typing 3 characters
      setLoading(true)
      try {
        const results = await ProfileService.searchUsersByNameOrEmail(query)
        setSearchResults(results)
      } catch (error) {
        console.error('Error searching users:', error)
        setSearchResults([])
      } finally {
        setLoading(false)
      }
    } else {
      setSearchResults([]) // Clear results when input is less than 3 characters
    }
  }

  // Add a selected user to the selectedUsers list
  const handleSelectUser = (user: {
    id: string
    name: string
    email: string
  }) => {
    // Check if the user is already selected based on their ID
    if (!selectedUsers.some((selectedUser) => selectedUser.id === user.id)) {
      // Add the full user object to selectedUsers and just the ID to selectedUsersIds
      setSelectedUsers([...selectedUsers, user])
      setSelectedUsersIds([...selectedUsersIds, user.id])
    }

    setUserSearch('') // Clear the search input
    setSearchResults([]) // Clear the search results
  }

  // Remove user from the list
  const handleRemoveUser = (user: {
    id: string
    name: string
    email: string
  }) => {
    // Remove the user from selectedUsers based on their ID
    setSelectedUsers(
      selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
    )
    // Also remove the user ID from selectedUsersIds
    setSelectedUsersIds(
      selectedUsersIds.filter((selectedUserId) => selectedUserId !== user.id)
    )
  }

  const handleSave = async (values: any) => {
    // setSubmit(true);
    const locationData = {
      locationRc: values.locationRc,
      locationName: values.locationName,
      standNumber: values.standNumber,
      standName: values.standName,
      blueBar: values.blueBar,
      conditionalVip: values.conditionalVip,
      rates: rates.filter((rate) => rate.checked).map((rate) => rate.rate),
      users: selectedUsersIds,
      allRates: rates, // Save rates including unchecked ones
      conditionalRates, // Save conditional rates including unchecked ones
      validations: rateValidation, // Save validations including unchecked ones

      // conditionalRates: conditionalRates
      //   .filter((rate) => rate.checked)
      //   .map((rate) => ({ rate1: rate.rate1, rate2: rate.rate2 })),
      // validations: rateValidation
      //   .filter((rate) => rate.checked)
      //   .map((rate) => ({ validation: rate.validation, name: rate.name })),
    }

    // return;

    try {
      if (mode === 'create') {
        await LocationService.createLocation(locationData)
        // Show success message when the location is created
        setAlert({
          show: true,
          message: 'Location created successfully!',
          variant: 'success',
        })

        console.log('Location created successfully')
      } else if (mode === 'edit') {
        await LocationService.updateLocation(location.id, locationData)
        // Show success message when the location is updated
        setAlert({
          show: true,
          message: 'Location updated successfully!',
          variant: 'success',
        })

        console.log('Location updated successfully')
      }
      setSubmit(false)
      // Close the modal after successful operation
      onClose()
    } catch (error) {
      setSubmit(false)
      console.error('Error saving location:', error)
      setAlert({
        show: true,
        message: 'An error occurred while saving the location.',
        variant: 'error',
      })
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='create-location-modal-title'
      aria-describedby='create-location-modal-description'
    >
      <Box sx={styleSheet.container}>
        <Formik
          initialValues={{
            locationRc: location?.locationRc || '',
            locationName: location?.locationName || '',
            standNumber: location?.standNumber || '',
            standName: location?.standName || '',
            blueBar: location?.blueBar || 'Yes',
            conditionalVip: location?.conditionalVip || 'Yes',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box>
                <Typography variant='h6' p={2} component='h2'>
                  {mode === 'create' ? 'Create Location' : 'Edit Location'}
                </Typography>
                <Box
                  borderTop='2px solid #E0E0E0'
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                ></Box>
                <Box
                  width={'100%'}
                  px={{ xs: '15px', md: 4 }}
                  display={'flex'}
                  flexDirection={{ xs: 'column', md: 'row' }}
                  //  alignItems={"center"}
                  gap={2}
                >
                  <Grid container width={'55%'}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        mt: 2,
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Location RC</Box>

                        <TextField
                          name='locationRc'
                          value={values.locationRc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='####'
                          disabled={user?.role !== 'Admin' && mode === 'edit'} // Set disabled conditionally
                          error={
                            touched.locationRc && Boolean(errors.locationRc)
                          }
                          helperText={
                            touched.locationRc && errors.locationRc
                              ? String(errors.locationRc)
                              : undefined
                          }
                          sx={{
                            width: '252px',
                            '& .MuiInputBase-input::placeholder': {
                              color: 'rgba(0, 0, 0, 0.6)',
                            },
                          }}
                        />
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Location Name</Box>

                        <TextField
                          name='locationName'
                          value={values.locationName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Name'
                          disabled={user?.role !== 'Admin' && mode === 'edit'}
                          error={
                            touched.locationName && Boolean(errors.locationName)
                          }
                          helperText={
                            touched.locationName && errors.locationName
                              ? String(errors.locationName)
                              : undefined
                          }
                          sx={{
                            width: '356px',
                            '& .MuiInputBase-input::placeholder': {
                              color: 'rgba(0, 0, 0, 0.6)',
                            },
                          }}
                        />
                      </Box>{' '}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        mt: 2,
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Stand #</Box>

                        <TextField
                          name='standNumber'
                          value={values.standNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='##'
                          disabled={user?.role !== 'Admin' && mode === 'edit'}
                          error={
                            touched.standNumber && Boolean(errors.standNumber)
                          }
                          helperText={
                            touched.standNumber && errors.standNumber
                              ? String(errors.standNumber)
                              : undefined
                          }
                          sx={{
                            width: '252px',
                            '& .MuiInputBase-input::placeholder': {
                              color: 'rgba(0, 0, 0, 0.6)',
                            },
                          }}
                        />
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Stand Name</Box>

                        <TextField
                          name='standName'
                          value={values.standName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Name'
                          disabled={user?.role !== 'Admin' && mode === 'edit'}
                          error={touched.standName && Boolean(errors.standName)}
                          helperText={
                            touched.standName && errors.standName
                              ? String(errors.standName)
                              : undefined
                          }
                          sx={{
                            width: '356px',
                            '& .MuiInputBase-input::placeholder': {
                              color: 'rgba(0, 0, 0, 0.6)',
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        mt: 2,
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Rates</Box>

                        {/* Grid for Rate Checkboxes */}
                        <Box
                          border={'2px solid #000000'}
                          borderRadius={'8px'}
                          p={2}
                          width={'596px'}
                        >
                          <Grid container spacing={0} gap={'0px'}>
                            {rates?.map((rate, index) => (
                              <Grid item xs={2} key={index}>
                                <Checkbox
                                  checked={rate.checked}
                                  onChange={() => handleRateChange(index)}
                                  size='small'
                                  sx={{
                                    color: '#000000',
                                    '&.Mui-checked': { color: '#0A9959' },
                                  }}
                                />
                                <Typography variant='body2' component='span'>
                                  ${rate.rate}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>

                          {/* Custom Rate Input */}
                          <Box display='flex' alignItems='center' mt={2}>
                            <Typography variant='body1' sx={{ mr: 1 }}>
                              Custom Rate:
                            </Typography>
                            <TextField
                              size='small'
                              placeholder='$'
                              value={customRate}
                              onChange={(e) => {
                                const value = e.target.value
                                if (/^\d*\.?\d*$/.test(value)) {
                                  setCustomRate(value)
                                }
                              }}
                              sx={{
                                width: '150px',
                                borderRadius: '8px',
                                '& .MuiInputBase-input::placeholder': {
                                  color: 'rgba(0, 0, 0, 0.6)',
                                },
                              }}
                            />
                            <IconButton onClick={handleAddCustomRate}>
                              <Box
                                component='img'
                                src='assetsOaks/icon/plusbtn.svg'
                                alt='export'
                              ></Box>
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* /// condition rates /// */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        mt: 2,
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Conditional Rates</Box>

                        {/* Grid for Rate Checkboxes */}
                        <Box
                          border={'2px solid #000000'}
                          borderRadius={'8px'}
                          p={2}
                          width={'596px'}
                        >
                          <Grid container spacing={2} width={'100%'}>
                            {conditionalRates.map((rateObj, index) => (
                              <Grid item xs={3} key={index}>
                                {' '}
                                {/* xs={3} to display 4 items in one row */}
                                <Checkbox
                                  checked={
                                    rateObj.checked === true ? true : false
                                  }
                                  onChange={() =>
                                    handleRateConditionChange(index)
                                  }
                                  size='small'
                                  sx={{
                                    color: '#000000',
                                    '&.Mui-checked': { color: '#0A9959' },
                                  }}
                                />
                                <Typography variant='body2' component='span'>
                                  ${rateObj.rate1} & ${rateObj.rate2}{' '}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>

                          {/* Custom Rate Input */}
                          <Box
                            display='flex'
                            alignItems='center'
                            gap={'4px'}
                            mt={2}
                          >
                            <Box display='flex' alignItems='center'>
                              <Typography variant='body1' sx={{ mr: 1 }}>
                                Conditional Rates:
                              </Typography>
                              <TextField
                                size='small'
                                placeholder='$'
                                value={ConditionRate}
                                onChange={(e) => {
                                  const value = e.target.value
                                  if (/^\d*\.?\d*$/.test(value)) {
                                    setConditionRate(value)
                                  }
                                }}
                                sx={{
                                  width: '99px',
                                  borderRadius: '8px',
                                  '& .MuiInputBase-input::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                  },
                                }}
                              />
                            </Box>

                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={'4px'}
                            >
                              <Typography variant='body1'>&</Typography>
                              <TextField
                                size='small'
                                placeholder='$'
                                value={ConditionRateSecond}
                                onChange={(e) => {
                                  const value = e.target.value
                                  if (/^\d*\.?\d*$/.test(value)) {
                                    setConditionRateSecond(value)
                                  }
                                }}
                                sx={{
                                  width: '99px',
                                  borderRadius: '8px',
                                  '& .MuiInputBase-input::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                  },
                                }}
                              />
                            </Box>

                            <IconButton onClick={handleAddConditionRate}>
                              <Box
                                component='img'
                                src='assetsOaks/icon/plusbtn.svg'
                                alt='export'
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* /// validations /// */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        mt: 2,
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Validations</Box>

                        {/* Grid for Rate Checkboxes */}
                        <Box
                          border={'2px solid #000000'}
                          borderRadius={'8px'}
                          p={2}
                          width={'596px'}
                        >
                          <Grid container spacing={2}>
                            {rateValidation.map((objt, index) => (
                              <Grid item xs={4} key={index}>
                                <Checkbox
                                  checked={objt.checked === true ? true : false}
                                  onChange={() =>
                                    handleRateValidationChange(index)
                                  }
                                  size='small'
                                  sx={{
                                    color: '#000000',
                                    '&.Mui-checked': { color: '#0A9959' },
                                  }}
                                />
                                <Typography variant='body2' component='span'>
                                  {objt.name} & (${objt.validation})
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>

                          {/* Custom Rate Input */}
                          <Box
                            display='flex'
                            alignItems='center'
                            gap={'4px'}
                            mt={2}
                          >
                            <Box display='flex' alignItems='center'>
                              <Typography variant='body1' sx={{ mr: 1 }}>
                                Custom Validation:
                              </Typography>
                              <TextField
                                size='small'
                                placeholder='$'
                                value={validations}
                                onChange={(e) => {
                                  const value = e.target.value
                                  if (/^\d*\.?\d*$/.test(value)) {
                                    setValidations(value)
                                  }
                                }}
                                sx={{
                                  width: '99px',
                                  borderRadius: '8px',
                                  '& .MuiInputBase-input::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                  },
                                }}
                              />
                            </Box>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={'4px'}
                            >
                              <Typography variant='body1'>Name</Typography>
                              <TextField
                                size='small'
                                placeholder='Enter Name'
                                value={NameRate}
                                onChange={(e) => setNameRate(e.target.value)}
                                sx={{
                                  width: '150px',
                                  borderRadius: '8px',
                                  '& .MuiInputBase-input::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                  },
                                }}
                              />
                            </Box>
                            <IconButton onClick={handleAddValidationRate}>
                              <Box
                                component='img'
                                src='assetsOaks/icon/plusbtn.svg'
                                alt='export'
                              ></Box>
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid width={'45%'}>
                    {/* Rates Section */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        mt: 2,
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Blue Bar</Box>
                        <Select
                          labelId='conditional-vip-label'
                          label='Conditional VIP'
                          value={values.blueBar}
                          defaultValue='Yes'
                          name='blueBar'
                          disabled={user?.role !== 'Admin' && mode === 'edit'}
                          onChange={handleChange}
                          sx={{
                            width: '250.58px',
                            height: '50px',
                            borderRadius: '8px',
                          }}
                        >
                          <MenuItem value='Yes'>Yes</MenuItem>
                          <MenuItem value='No'>No</MenuItem>
                        </Select>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <Box sx={styleSheet.labelText}>Conditional VIP</Box>

                        <Select
                          labelId='conditional-vip-label'
                          label='Conditional VIP'
                          defaultValue='Yes'
                          value={values.conditionalVip}
                          name='conditionalVip'
                          disabled={user?.role !== 'Admin' && mode === 'edit'}
                          onChange={handleChange}
                          sx={{
                            width: '250.58px',
                            height: '50px',
                            borderRadius: '8px',
                          }}
                        >
                          <MenuItem value='Yes'>Yes</MenuItem>
                          <MenuItem value='No'>No</MenuItem>
                        </Select>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '7px',
                        mt: 2,
                      }}
                    >
                      <Box sx={styleSheet.labelText}>Add Users</Box>

                      <TextField
                        fullWidth
                        placeholder='Search for users by name or email'
                        variant='outlined'
                        value={userSearch}
                        onChange={handleUserSearchChange}
                        disabled={user?.role !== 'Admin' && mode === 'edit'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '529px',
                          mt: 2,
                          '& .MuiInputBase-input::placeholder': {
                            color: 'rgba(0, 0, 0, 0.6)',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            height: '52px',
                          },
                        }}
                      />

                      {/* Display search results */}
                      {loading && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 2,
                          }}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      )}
                      {!loading && searchResults.length > 0 && (
                        <Box sx={styleSheet.searchResultsContainer}>
                          {searchResults.map((user) => (
                            <Box
                              key={user.id}
                              sx={styleSheet.searchResultItem}
                              onClick={() => handleSelectUser(user)}
                            >
                              {user.name} - {user.email}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        mt: 2,
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        {/* Grid for Rate Checkboxes */}
                        <Box
                          border={'2px solid #000000'}
                          borderRadius={'8px'}
                          p={2}
                          width={'496px'}
                          minHeight={'209px'}
                        >
                          <Box
                            display={'flex'}
                            gap={'8px'}
                            sx={{
                              flexWrap: 'wrap', // Allows chips to wrap to the next row
                              overflow: 'hidden',
                            }}
                          >
                            {selectedUsers.map((users, index) => (
                              <Grid key={index}>
                                <Chip
                                  label={users.name}
                                  onDelete={() => handleRemoveUser(users)}
                                  sx={{
                                    width: '160px', // Make sure the chip takes the full width of the grid
                                    height: '40px',
                                    backgroundColor: '#0A9959',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    borderRadius: '4px',
                                    '& .MuiChip-deleteIcon': {
                                      color: 'white',
                                    },
                                  }}
                                  deleteIcon={
                                    <Box
                                      component='img'
                                      src='assetsOaks/icon/cancel.svg'
                                      alt='delete'
                                    />
                                  }
                                  disabled={
                                    user?.role !== 'Admin' && mode === 'edit'
                                  }
                                />
                              </Grid>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Box>
              {/* Save Button */}
              <Box sx={{ mt: 4, textAlign: 'center', gap: 2 }}>
                <Button
                  type='submit'
                  variant='contained'
                  sx={{
                    height: '40px',
                    backgroundColor: '#0A9959',
                    color: '#fff',
                  }}
                >
                  {submit ? (
                    <>
                      <CircularProgress
                        size={20}
                        sx={{ color: '#fff', marginRight: '10px' }}
                      />
                      Saving Location...
                    </>
                  ) : (
                    <>
                      <Box
                        component='img'
                        src='/assetsOaks/icon/save.svg'
                        alt='save'
                        sx={{
                          mr: 1,
                        }}
                      />
                      Save
                    </>
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  )
}

export default CreateLocationModal

const styleSheet = {
  // Styles for the modal
  container: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 1304 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '90vh',
    overflowY: 'auto',
    pb: 10,
    borderRadius: '10px',
  },
  labelText: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
    lineHeight: '19.2px',
    letterSpacing: '0.15px',
  },
  inputField: {
    '& .MuiInputBase-input::placeholder': {
      color: 'rgba(0, 0, 0, 0.6)', // Adjust this color to whatever you prefer
    },
  },
  searchResultsContainer: {
    mt: 1,
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    maxHeight: '150px',
    overflowY: 'auto',
  },
  searchResultItem: {
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
}
