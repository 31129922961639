import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { EntryService } from 'services/Entry.Services'
import { LocationService } from 'services/Location.Services'
import { Entry, Location, GroupedLocation } from 'models/schema'
import { FirestoreError } from 'firebase/firestore'
import { AppLayout } from 'layout'
import { CBox } from 'components'
import { EntriesData } from 'modules/Entries/components'
import { useDataContext } from 'context/DataContext'
import { useAuthContext } from 'context/AuthContext'
const EntriesContainer = () => {
  const [entries, setEntries] = useState<Entry[]>([]) // Local state to store admins
  const [locations, setLocations] = useState<GroupedLocation[]>([]) // Local state to store locations
  const [error, setError] = useState<FirestoreError | null>(null) // State to store errors
  const [loading, setLoading] = useState(true) // Loading state to handle loading UI
  const { dates } = useDataContext()
  const { user } = useAuthContext()
  const locationIds = user?.locationIds || []
  const role = user?.role || ''
  //write function to fetch location by rc
  const fetchLocation = async (locationRc: string) => {
    const locationsData = await LocationService.searchLocationByRc(locationRc)
    console.log('locationData', locationsData)
  }

  useEffect(() => {
    const entryDataCallback = (entryList: Entry[]) => {
      setEntries(entryList) // Update state with the fetched entries
      setLoading(false) // Data loaded, hide the loader
    }

    const locationDataCallback = (locationList: GroupedLocation[]) => {
      setLocations(locationList)
      // setLoading(false); // Data loaded, hide the loader
    }

    const errorCallback = (error: FirestoreError) => {
      setError(error)
      setLoading(false) // Error occurred, hide the loader
    }
    fetchLocation('5009')

    setLoading(true) // Show loader when data request starts
    const startDate = dates.startDate // Replace with actual date picker values
    const endDate = dates.endDate // Replace with actual date picker values
    // console.log(startDate, endDate);
    // alert("Fetching data for the selected dates");
    console.log(dates, 'dates are')
    const unsubscribeEntries = EntryService.getAllEntries(
      startDate,
      endDate,
      locationIds,
      role,
      entryDataCallback, // Update state in entryDataCallback
      errorCallback // Handle errors in errorCallback
    )

    const unsubscribeLocations = LocationService.getGroupedLocations(
      locationIds,
      role,
      locationDataCallback,
      errorCallback
    )

    return () => {
      unsubscribeEntries() // Unsubscribe from entries when component unmounts
      unsubscribeLocations() // Unsubscribe from locations when component unmounts
    }
  }, [dates, user])

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <AppLayout title='Entries'>
      <CBox col height={'100%'} gap={5} px={4}>
        {/* Show loader only in the main content area */}
        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
          >
            <CircularProgress />
          </Box>
        ) : (
          <EntriesData entries={entries} locations={locations} />
        )}
      </CBox>
    </AppLayout>
  )
}

export default EntriesContainer
