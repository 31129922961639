import React, { useState, useMemo } from 'react'
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableContainer,
  Typography,
} from '@mui/material'
import { Timestamp } from 'firebase/firestore'
import { Location } from 'models/schema'

interface Song {
  id: number
  startDate: Timestamp
  endDate: Timestamp
  locationId: string
  location: Location
  stand: string
  totalCars: number
  totalRevenue: number
  totalValidationCars: number
  totalValidationRevenue: number
  totalCreditCard: number
  totalConditinalTips: number
  totalTips: number
  totalCcTipsPaid: number
  totalCreditCardSurcharge: number
  totalblubar: number
  totalcalculatedCashTurnIn: number
  totalDepositAmount: number
}

type Order = 'asc' | 'desc'

interface RevenueTableProps {
  tableData: Song[]
  stands: string | null // Stands can now be `null` to hide columns
  triggerExport: boolean // Detect export trigger from parent
  onExportComplete: () => void // Callback to reset trigger in parent
}

const RevenueTable: React.FC<RevenueTableProps> = ({
  tableData,
  stands,
  triggerExport,
  onExportComplete,
}) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('totalRevenue') // Default sorting column

  console.log('stands', stands)

  // Mapping column headers to Song keys
  const columnMapping: Record<string, string> = {
    'LOCATION RC': 'location.locationRc',
    'LOCATION Name': 'location.locationName',
    'STAND #': 'stand',
    CARS: 'totalCars',
    REVENUE: 'totalRevenue',
    'VALIDATED CARS': 'totalValidationCars',
    'VALIDATION AMOUNT': 'totalValidationRevenue',
    'CREDIT CARDS': 'totalCreditCard',
    'CONDITIONAL TIPS': 'totalConditinalTips',
    'TOTAL TIPS': 'totalTips',
    'PAYROLL TIPS': 'totalCcTipsPaid',
    'CC SURCHARGE': 'totalCreditCardSurcharge',
    'BLUE BAR': 'totalblubar',
    CALCULATED: 'totalcalculatedCashTurnIn',
    'DEPOSIT AMOUNT': 'totalDepositAmount',
  }

  const handleRequestSort = (headCell: string) => {
    const property = columnMapping[headCell]
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getValue = (obj: any, path: string): any =>
    path.split('.').reduce((o, key) => o?.[key], obj)

  // Summarize data by RC if stands is null
  const summarizedData: Song[] = useMemo(() => {
    if (stands !== 'Stand 1') return tableData

    return Object.values(
      tableData.reduce((acc: Record<string, Song>, curr: Song) => {
        const rc = curr.location.locationRc
        if (!acc[rc]) {
          acc[rc] = {
            ...curr,
            totalCars: 0,
            totalRevenue: 0,
            totalValidationCars: 0,
            totalValidationRevenue: 0,
            totalCreditCard: 0,
            totalConditinalTips: 0,
            totalTips: 0,
            totalCcTipsPaid: 0,
            totalCreditCardSurcharge: 0,
            totalblubar: 0,
            totalcalculatedCashTurnIn: 0,
            totalDepositAmount: 0,
          }
        }
        acc[rc].totalCars += curr.totalCars || 0
        acc[rc].totalRevenue += curr.totalRevenue || 0
        acc[rc].totalValidationCars += curr.totalValidationCars || 0
        acc[rc].totalValidationRevenue += curr.totalValidationRevenue || 0
        acc[rc].totalCreditCard += curr.totalCreditCard || 0
        acc[rc].totalConditinalTips += curr.totalConditinalTips || 0
        acc[rc].totalTips += curr.totalTips || 0
        acc[rc].totalCcTipsPaid += curr.totalCcTipsPaid || 0
        acc[rc].totalCreditCardSurcharge += curr.totalCreditCardSurcharge || 0
        acc[rc].totalblubar += curr.totalblubar || 0
        acc[rc].totalcalculatedCashTurnIn += curr.totalcalculatedCashTurnIn || 0
        acc[rc].totalDepositAmount += curr.totalDepositAmount || 0
        return acc
      }, {})
    )
  }, [tableData, stands])

  const sortedSongs: Song[] = useMemo(() => {
    return [...summarizedData].sort((a, b) => {
      const aValue = getValue(a, orderBy)
      const bValue = getValue(b, orderBy)

      // Handle undefined values
      if (aValue === undefined || bValue === undefined) return 0

      // Handle date sorting
      if (orderBy === 'startDate' || orderBy === 'endDate') {
        const aDate =
          aValue instanceof Timestamp ? aValue.toDate() : new Date(aValue)
        const bDate =
          bValue instanceof Timestamp ? bValue.toDate() : new Date(bValue)

        return order === 'asc'
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime()
      }

      // Handle numeric sorting
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? aValue - bValue : bValue - aValue
      }

      // Handle string sorting
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue)
      }

      return 0 // Default case for unhandled types
    })
  }, [summarizedData, order, orderBy])

  const handleExport = () => {
    const headers = Object.keys(columnMapping).filter(
      (key) => stands !== 'Stand 1' || key !== 'STAND #'
    )

    let csvContent = headers.join(',') + '\n'

    sortedSongs.forEach((song) => {
      const row = headers.map((header) => {
        const key = columnMapping[header]
        const value = getValue(song, key)

        // Format numeric values to 2 decimal places
        if (typeof value === 'number') {
          return value.toFixed(2)
        }

        return value ?? '' // Return an empty string for null/undefined values
      })

      csvContent += row.join(',') + '\n'
    })

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.setAttribute('download', 'summary-report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    onExportComplete() // Notify parent to reset the export trigger
  }

  // Trigger export when parent signals
  React.useEffect(() => {
    if (triggerExport) {
      handleExport()
    }
  }, [triggerExport])

  return (
    <React.Fragment>
      <Box
        sx={{
          width: { xs: '100%', md: 'calc(100vw - 30vw)' },
          overflowX: 'auto',
          padding: { xs: '1px', md: '40px' },
          zIndex: 10,
        }}
      >
        <Typography variant='h6' mb={1}>
          Revenue Report
        </Typography>
        <TableContainer component={Paper} sx={{ flex: 1 }}>
          <Table className='report-table' sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ bgcolor: '#EAEAEA' }}>
                {Object.keys(columnMapping)
                  .filter((key) => stands !== 'Stand 1' || key !== 'STAND #')
                  .map((headCell, index) => (
                    <TableCell key={index}>
                      <TableSortLabel
                        active={orderBy === columnMapping[headCell]}
                        direction={
                          orderBy === columnMapping[headCell] ? order : 'asc'
                        }
                        onClick={() => handleRequestSort(headCell)}
                      >
                        {headCell}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSongs.map((song, index) => (
                <TableRow key={index}>
                  <TableCell>{song.location?.locationRc}</TableCell>
                  <TableCell>{song.location?.locationName}</TableCell>
                  {stands !== 'Stand 1' && <TableCell>{song.stand}</TableCell>}
                  <TableCell>{song.totalCars?.toFixed(2)}</TableCell>
                  <TableCell>{song.totalRevenue?.toFixed(2)}</TableCell>
                  <TableCell>{song.totalValidationCars?.toFixed(2)}</TableCell>
                  <TableCell>
                    {song.totalValidationRevenue?.toFixed(2)}
                  </TableCell>
                  <TableCell>{song.totalCreditCard?.toFixed(2)}</TableCell>
                  <TableCell>{song.totalConditinalTips?.toFixed(2)}</TableCell>
                  <TableCell>{song.totalTips?.toFixed(2)}</TableCell>
                  <TableCell>{song.totalCcTipsPaid?.toFixed(2)}</TableCell>
                  <TableCell>
                    {song.totalCreditCardSurcharge?.toFixed(2)}
                  </TableCell>
                  <TableCell>{song.totalblubar?.toFixed(2)}</TableCell>
                  <TableCell>
                    {song.totalcalculatedCashTurnIn?.toFixed(2)}
                  </TableCell>
                  <TableCell>{song?.totalDepositAmount?.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  )
}

export default RevenueTable
