import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { AppLayout } from 'layout'
import { CBox } from 'components'
import { LocationData } from 'modules/Location/components'
import { LocationService } from 'services/Location.Services'
import { FirestoreError } from 'firebase/firestore'
import { Location } from 'models/schema'
import { useAuthContext } from 'context/AuthContext'

type Props = {}

const LocationContainer = (props: Props) => {
  const [locations, setLocations] = useState<Location[]>([]) // Local state to store locations
  const [error, setError] = useState<FirestoreError | null>(null) // State to store errors
  const [loading, setLoading] = useState(true) // Loading state to handle loader
  const { user } = useAuthContext()
  const locationIds = user?.locationIds || null
  const role = user?.role || ''
  useEffect(() => {
    // Data callback to handle real-time location data
    const dataCallback = (locationList: Location[]) => {
      setLocations(locationList) // Update the state with real-time data
      // console.log("LocationList", locationList);
      setLoading(false) // Hide loader once data is loaded
    }

    // Error callback to handle any Firestore errors
    const errorCallback = (error: FirestoreError) => {
      setError(error) // Set error state
      setLoading(false) // Hide loader in case of an error
    }

    // Show loader when the request starts
    setLoading(true)

    // Subscribe to real-time updates for the locations collection via LocationService
    // const unsubscribe = LocationService.getAllLocations(
    //   dataCallback,
    //   errorCallback
    // );

    const unsubscribe = LocationService.getAllLocations2(
      locationIds,
      role,
      dataCallback,
      errorCallback
    )

    // Clean up the subscription when the component unmounts
    return () => unsubscribe()
  }, [user]) // Run effect only on mount and unmount

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <AppLayout title='Location'>
      <CBox col height={'100%'} gap={5} px={4}>
        {/* Show loader only in the main content area */}
        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
          >
            <CircularProgress />
          </Box>
        ) : (
          <LocationData locations={locations} />
        )}
      </CBox>
    </AppLayout>
  )
}

export default LocationContainer
